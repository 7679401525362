import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '@env/environment';

/**
 * Prefixes all requests with `environment.serverUrl`.
 */
@Injectable()
export class ApiPrefixInterceptor implements HttpInterceptor {
  private finalUrl;

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let env = 'serverUrl';

    if (
        !/^(http|https):/i.test(request.url) &&
        !/assets/i.test(request.url)
    ) {
      this.finalUrl = request.url;

      if (/^\{.*\}/i.test(request.url)) {
        env = request.url.match(/{(.*)}/)[1];
        this.finalUrl = request.url.replace(/{(.*)}/, '');
      }

      request = request.clone({ url: environment[env] + this.finalUrl });
    }
    return next.handle(request);
  }
}
