import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseModalService } from '@shared/modal/services/base-modal.service';

@NgModule({
  imports: [
    CommonModule,
  ],
  providers: [
    BaseModalService
  ],
  declarations: [],
  exports: []
})
export class CommonModalModule {}

