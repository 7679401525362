import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { AuthenticationService } from './authentication/authentication.service';
import { AuthenticationGuard } from './authentication/authentication.guard';
import { HttpCacheService } from './http/http-cache.service';
import { CacheInterceptor } from './http/cache.interceptor';
import { ApiPrefixInterceptor } from './http/api-prefix.interceptor';
import { ErrorHandlerInterceptor } from './http/error-handler.interceptor';
import { HttpService } from './http/http.service';
import { TokenInterceptor } from './http/token.interceptor';
import { BariCallmenuDirective } from '@bari/shared/directives/callmenu.directive';
import { CustomersService } from './services/customers.service';
import { GlobalErrorHandlerModule } from './error/errorhandler.module';
import { AccountsService } from './services/accounts.service';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    RouterModule,
    GlobalErrorHandlerModule
  ],
  providers: [
    AuthenticationService,
    AuthenticationGuard,
    HttpCacheService,
    CacheInterceptor,
    ApiPrefixInterceptor,
    ErrorHandlerInterceptor,
    TokenInterceptor,
    CustomersService,
    AccountsService,
    {
      provide: HttpClient,
      useClass: HttpService
    }
    // {
    //   provide: RouteReuseStrategy,
    //   useClass: RouteReusableStrategy
    // }
  ],
  declarations: [
    BariCallmenuDirective
  ],
  exports: [
    BariCallmenuDirective
  ]
})
export class CoreModule {

  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    // Import guard
    if (parentModule) {
      throw new Error(`${parentModule} has already been loaded. Import Core module in the AppModule only.`);
    }
  }

}
