import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BariToastrComponent } from './bari-toastr.component';
import { ToastrModule } from 'ngx-toastr';

@NgModule({
  declarations: [BariToastrComponent],
  imports: [
    CommonModule,
    ToastrModule.forRoot({
      positionClass: 'toast-top-center',
      toastComponent: BariToastrComponent
    })
  ]
})
export class BariToastrModule { }
