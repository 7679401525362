import { Router } from '@angular/router';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
var ErrorService = (function () {
    function ErrorService(_router) {
        this._router = _router;
        this._defaultUrlToRedirect = '/account';
    }
    ErrorService.prototype.getClientMessage = function (error) {
        if (!navigator.onLine) {
            return 'No Internet Connection';
        }
        return error.message ? error.message : error.toString();
    };
    ErrorService.prototype.getClientStack = function (error) {
        return error.stack;
    };
    ErrorService.prototype.getServerMessage = function (error) {
        return error.message;
    };
    ErrorService.prototype.getServerStack = function (error) {
        return 'stack';
    };
    ErrorService.prototype.redirectErrorPage = function () {
        this._router.navigate(['/error'], {
            state: {
                oldUrl: this._router.url === '/error' ? this._defaultUrlToRedirect : this._router.url
            }
        });
    };
    ErrorService.ngInjectableDef = i0.defineInjectable({ factory: function ErrorService_Factory() { return new ErrorService(i0.inject(i1.Router)); }, token: ErrorService, providedIn: "root" });
    return ErrorService;
}());
export { ErrorService };
