import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { Toast, ToastPackage, ToastrService } from 'ngx-toastr';
var BariToastrComponent = (function (_super) {
    tslib_1.__extends(BariToastrComponent, _super);
    function BariToastrComponent(toastrService, toastPackage) {
        var _this = _super.call(this, toastrService, toastPackage) || this;
        _this.toastrService = toastrService;
        _this.toastPackage = toastPackage;
        return _this;
    }
    BariToastrComponent.prototype.ngOnInit = function () {
    };
    BariToastrComponent.prototype.action = function (event) {
        event.stopPropagation();
        this.toastPackage.triggerAction();
        return false;
    };
    return BariToastrComponent;
}(Toast));
export { BariToastrComponent };
