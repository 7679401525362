import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faBars } from '@fortawesome/free-solid-svg-icons';
library.add(faBars);

import { BariMenuComponent } from '@bari/shell/components/bari-menu/bari-menu.component';
import { RouterModule } from '@angular/router';
import { CoreModule } from '@bari/core';
import { BariButtonModule } from '@bari/shared/components/bari-button/bari-button.module';

@NgModule({
  imports: [
    CommonModule,
    CoreModule,
    RouterModule,
    FontAwesomeModule,
    BariButtonModule
  ],
  declarations: [
    BariMenuComponent
  ],
  exports: [BariMenuComponent]
})
export class BariMenuModule { }
