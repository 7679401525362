import * as i0 from "./bari-section-menu.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../shared/components/bari-button/bari-button.component.ngfactory";
import * as i3 from "@angular/router";
import * as i4 from "../../../shared/components/bari-button/bari-button.component";
import * as i5 from "../../../core/services/feature-flag.service";
import * as i6 from "@angular/common/http";
import * as i7 from "../../../shared/directives/feature-flag.directive";
import * as i8 from "@angular/common";
import * as i9 from "./bari-section-menu.component";
var styles_BariSectionMenuComponent = [i0.styles];
var RenderType_BariSectionMenuComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BariSectionMenuComponent, data: {} });
export { RenderType_BariSectionMenuComponent as RenderType_BariSectionMenuComponent };
function View_BariSectionMenuComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "bari-button", [["class", "btn"], ["color", "transparent"], ["direction", "row-reverse"], ["size", "medium"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_BariButtonComponent_0, i2.RenderType_BariButtonComponent)), i1.ɵdid(1, 16384, [[1, 4]], 0, i3.RouterLink, [i3.Router, i3.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(2, 1), i1.ɵdid(3, 1720320, null, 2, i3.RouterLinkActive, [i3.Router, i1.ElementRef, i1.Renderer2, i1.ChangeDetectorRef], { routerLinkActive: [0, "routerLinkActive"] }, null), i1.ɵqud(603979776, 1, { links: 1 }), i1.ɵqud(603979776, 2, { linksWithHrefs: 1 }), i1.ɵpad(6, 1), i1.ɵdid(7, 638976, null, 0, i4.BariButtonComponent, [i1.ElementRef, i1.Renderer2], { icon: [0, "icon"], class: [1, "class"], color: [2, "color"], size: [3, "size"], direction: [4, "direction"] }, null), i1.ɵpad(8, 1), (_l()(), i1.ɵted(9, 0, [" ", " "]))], function (_ck, _v) { var currVal_0 = _ck(_v, 2, 0, _v.parent.context.$implicit.href); _ck(_v, 1, 0, currVal_0); var currVal_1 = _ck(_v, 6, 0, "active"); _ck(_v, 3, 0, currVal_1); var currVal_2 = _ck(_v, 8, 0, _v.parent.context.$implicit.icon); var currVal_3 = "btn"; var currVal_4 = "transparent"; var currVal_5 = "medium"; var currVal_6 = "row-reverse"; _ck(_v, 7, 0, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }, function (_ck, _v) { var currVal_7 = _v.parent.context.$implicit.name; _ck(_v, 9, 0, currVal_7); }); }
function View_BariSectionMenuComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "li", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_BariSectionMenuComponent_3)), i1.ɵprd(512, null, i5.FeatureFlagService, i5.FeatureFlagService, [i6.HttpClient]), i1.ɵdid(3, 81920, null, 0, i7.BariFeatureFlagDirective, [i1.TemplateRef, i1.ViewContainerRef, i5.FeatureFlagService], { bariFlag: [0, "bariFlag"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit.flag; _ck(_v, 3, 0, currVal_0); }, null); }
function View_BariSectionMenuComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "nav", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "ul", [["class", "menu-block"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BariSectionMenuComponent_2)), i1.ɵdid(3, 278528, null, 0, i8.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.menuSection; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_BariSectionMenuComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 2, null, View_BariSectionMenuComponent_1)), i1.ɵprd(512, null, i5.FeatureFlagService, i5.FeatureFlagService, [i6.HttpClient]), i1.ɵdid(2, 81920, null, 0, i7.BariFeatureFlagDirective, [i1.TemplateRef, i1.ViewContainerRef, i5.FeatureFlagService], { bariFlag: [0, "bariFlag"] }, null)], function (_ck, _v) { var currVal_0 = "section-menu"; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_BariSectionMenuComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "bari-section-menu", [], null, null, null, View_BariSectionMenuComponent_0, RenderType_BariSectionMenuComponent)), i1.ɵdid(1, 114688, null, 0, i9.BariSectionMenuComponent, [i3.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BariSectionMenuComponentNgFactory = i1.ɵccf("bari-section-menu", i9.BariSectionMenuComponent, View_BariSectionMenuComponent_Host_0, {}, {}, []);
export { BariSectionMenuComponentNgFactory as BariSectionMenuComponentNgFactory };
