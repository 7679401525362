import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
// import { AngularSvgIconModule } from 'angular-svg-icon';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from '@bari/app.routing.module';
import { AppComponent } from '@bari/app.component';
// Import Locale things
import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';
import { CoreModule, HttpService } from './core';

import { ShellModule } from '@bari/shell/shell.module';
import { CommonModalModule } from './shared/modal/modal.module';
import { BariToastrModule } from '@bari/shared/components/bari-toastr/bari-toastr.module';
import { BariToastrComponent } from '@bari/shared/components/bari-toastr/bari-toastr.component';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { FeatureToggleGuard } from './core/guard/feature-flag.guard';



// the second parameter 'Pt' is optional
registerLocaleData(localePt, 'pt');

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    ShellModule,
    BrowserModule,
    // AngularSvgIconModule,
    HttpClientModule,
    BrowserAnimationsModule,
    CoreModule,
    AppRoutingModule,
    RouterModule,
    BariToastrModule,
    CommonModalModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  entryComponents: [
    BariToastrComponent
  ],
  providers: [
    FeatureToggleGuard,
    { provide: LOCALE_ID, useValue: 'pt' },
    {
      provide: HttpClient,
      useClass: HttpService
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
