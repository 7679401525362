import * as i0 from "./bari-avatar.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../directives/click-outside.directive";
import * as i3 from "../bari-button/bari-button.component.ngfactory";
import * as i4 from "../bari-button/bari-button.component";
import * as i5 from "@angular/common";
import * as i6 from "@angular/router";
import * as i7 from "./bari-avatar.component";
import * as i8 from "../../../core/services/customers.service";
import * as i9 from "../../../core/authentication/authentication.service";
var styles_BariAvatarComponent = [i0.styles];
var RenderType_BariAvatarComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BariAvatarComponent, data: { "animation": [{ type: 7, name: "enterAnimation", definitions: [{ type: 1, expr: ":enter", animation: [{ type: 6, styles: { transform: "translateY(-50%)", opacity: 0 }, offset: null }, { type: 4, styles: { type: 6, styles: { transform: "translateY(0)", opacity: 1 }, offset: null }, timings: "200ms" }], options: null }, { type: 1, expr: ":leave", animation: [{ type: 6, styles: { transform: "translateY(0)", opacity: 1 }, offset: null }, { type: 4, styles: { type: 6, styles: { transform: "translateY(50%)", opacity: 0 }, offset: null }, timings: "150ms" }], options: null }], options: {} }] } });
export { RenderType_BariAvatarComponent as RenderType_BariAvatarComponent };
function View_BariAvatarComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [], [[8, "src", 4], [8, "alt", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.avatar, ""); var currVal_1 = i1.ɵinlineInterpolate(1, "Foto de Perfil de ", _co.getName(), ""); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_BariAvatarComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "no-image"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getName(true); _ck(_v, 1, 0, currVal_0); }); }
export function View_BariAvatarComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { submenu: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 30, "div", [["class", "avatar-content"], ["clickOutside", ""]], null, [[null, "clickOutside"], [null, "click"], ["document", "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("document:click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onClick($event) !== false);
        ad = (pd_0 && ad);
    } if (("clickOutside" === en)) {
        var pd_1 = (_co.verifyMenu() !== false);
        ad = (pd_1 && ad);
    } if (("click" === en)) {
        var pd_2 = (_co.toggleSubmenu() !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 16384, null, 0, i2.ClickOutsideDirective, [i1.ElementRef], null, { clickOutside: "clickOutside" }), (_l()(), i1.ɵeld(3, 0, null, null, 6, "div", [["class", "avatar-name"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "bari-button", [], null, null, null, i3.View_BariButtonComponent_0, i3.RenderType_BariButtonComponent)), i1.ɵdid(5, 638976, null, 0, i4.BariButtonComponent, [i1.ElementRef, i1.Renderer2], { icon: [0, "icon"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 1, "span", [["class", "welcome"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Ol\u00E1,"])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "span", [["class", "name"]], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, ["\u00A0", ""])), (_l()(), i1.ɵeld(10, 0, null, null, 4, "div", [["class", "avatar-image"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BariAvatarComponent_1)), i1.ɵdid(12, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BariAvatarComponent_2)), i1.ɵdid(14, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(15, 0, [[1, 0], ["submenu", 1]], null, 16, "div", [["class", "submenu closed"]], [[24, "@enterAnimation", 0]], null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 15, "ul", [], null, null, null, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 9, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(18, 0, null, null, 8, "bari-button", [["class", "btn"], ["color", "transparent"], ["direction", "row-reverse"], ["size", "medium"], ["text", "left"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 20).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_BariButtonComponent_0, i3.RenderType_BariButtonComponent)), i1.ɵdid(19, 638976, null, 0, i4.BariButtonComponent, [i1.ElementRef, i1.Renderer2], { class: [0, "class"], color: [1, "color"], text: [2, "text"], size: [3, "size"], direction: [4, "direction"] }, null), i1.ɵdid(20, 16384, [[2, 4]], 0, i6.RouterLink, [i6.Router, i6.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(21, 1), i1.ɵdid(22, 1720320, null, 2, i6.RouterLinkActive, [i6.Router, i1.ElementRef, i1.Renderer2, i1.ChangeDetectorRef], { routerLinkActive: [0, "routerLinkActive"] }, null), i1.ɵqud(603979776, 2, { links: 1 }), i1.ɵqud(603979776, 3, { linksWithHrefs: 1 }), i1.ɵpad(25, 1), (_l()(), i1.ɵted(-1, 0, [" Meu Perfil "])), (_l()(), i1.ɵeld(27, 0, null, null, 0, "li", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(28, 0, null, null, 3, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(29, 0, null, null, 2, "bari-button", [["color", "transparent"], ["direction", "row-reverse"], ["size", "medium"], ["text", "left"]], null, [[null, "action"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("action" === en)) {
        var pd_0 = (_co.logout() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_BariButtonComponent_0, i3.RenderType_BariButtonComponent)), i1.ɵdid(30, 638976, null, 0, i4.BariButtonComponent, [i1.ElementRef, i1.Renderer2], { color: [0, "color"], text: [1, "text"], size: [2, "size"], direction: [3, "direction"] }, { action: "action" }), (_l()(), i1.ɵted(-1, 0, [" Sair "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.menuIsOpen ? "angle-up" : "angle-down"); _ck(_v, 5, 0, currVal_0); var currVal_2 = _co.avatar; _ck(_v, 12, 0, currVal_2); var currVal_3 = !_co.avatar; _ck(_v, 14, 0, currVal_3); var currVal_5 = "btn"; var currVal_6 = "transparent"; var currVal_7 = "left"; var currVal_8 = "medium"; var currVal_9 = "row-reverse"; _ck(_v, 19, 0, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9); var currVal_10 = _ck(_v, 21, 0, "/profile"); _ck(_v, 20, 0, currVal_10); var currVal_11 = _ck(_v, 25, 0, "active"); _ck(_v, 22, 0, currVal_11); var currVal_12 = "transparent"; var currVal_13 = "left"; var currVal_14 = "medium"; var currVal_15 = "row-reverse"; _ck(_v, 30, 0, currVal_12, currVal_13, currVal_14, currVal_15); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.getName(); _ck(_v, 9, 0, currVal_1); var currVal_4 = undefined; _ck(_v, 15, 0, currVal_4); }); }
export function View_BariAvatarComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "bari-avatar", [], null, null, null, View_BariAvatarComponent_0, RenderType_BariAvatarComponent)), i1.ɵdid(1, 114688, null, 0, i7.BariAvatarComponent, [i8.CustomersService, i9.AuthenticationService, i6.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BariAvatarComponentNgFactory = i1.ɵccf("bari-avatar", i7.BariAvatarComponent, View_BariAvatarComponent_Host_0, {}, {}, []);
export { BariAvatarComponentNgFactory as BariAvatarComponentNgFactory };
