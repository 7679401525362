import { Component, OnInit } from '@angular/core';
import { CustomersService } from '@bari/core/services/customers.service';
import { AuthenticationService } from '@bari/core';
import { WalletService } from '@bari/core/services/wallet.service';
import { ErrorService } from '@bari/core/error/services/error.service';

@Component({
  selector: 'bari-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {


  public name: string;
  public email: string;
  public phone: string;

  public user;
  public agency;
  public accountId;

  public busy = false;
  public busyProfileData = true;

  constructor(private _service: CustomersService, private _authService: AuthenticationService,
    private _servicewallet: WalletService, private _errorService: ErrorService) { }

  private  _getWallet(): Promise<any> {

    return new Promise(async (resolve, reject) => {
      this.busy = false;
      try {

        const user = await this._service.getAllCustomerInformation(
          this._authService.credentials.username

          );
          this.user = user.id;

          const wallets: any = await this._servicewallet.getWallet(this.user);

          this.agency = wallets.agency;
          this.accountId = wallets.accountId;

          this.busyProfileData = false;

          resolve(this.agency);
        } catch (e) {
          this._errorService.redirectErrorPage();
          reject(e);
        }

      });


    }


  public getName(firstLetter?: boolean) {
    if (this.name) {
      if (firstLetter) {
        return this.name[0];
      } else {
        return this.name.split(' ')[0];
      }
    }
  }


  public copyToClipboard() {
    const el = document.getElementById('clipBoard');
    el.setAttribute('contenteditable', 'true');
    el.focus();
    document.execCommand('selectAll');
    document.execCommand('copy');
    el.setAttribute('contenteditable', 'false');
    el.blur();
  }

  async ngOnInit() {
    this._getWallet();
    if (this._authService.credentials) {
      const user = await this._service.getAllCustomerInformation(this._authService.credentials.username);
      this.name = user.name;
      this.email = user.mainEmailAddress;
      this.phone = user.mainPhoneNumber;

    }
  }
}
