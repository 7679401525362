import { NgModule, ErrorHandler } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GlobalErrorHandler } from './global-error-handler';
@NgModule({
  imports: [
    CommonModule
  ],
  providers: [
    { provide: ErrorHandler, useFactory: GlobalErrorHandler },
  ]
})
export class GlobalErrorHandlerModule {

}
