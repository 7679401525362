import { ActivatedRouteSnapshot, Router } from '@angular/router';

export class ShellHelper {
  constructor(private router: Router) { }

  public getRouteHeaderState(): boolean {
    return this.getRouteData('header') === undefined ? true : this.getRouteData('header');
  }

  public getRouteTitle(): string {
    return this.getRouteData('title');
  }

  private getRouteData(data: string): any {
    const root = this.router.routerState.snapshot.root;
    return this.lastChild(root).data[data];
  }

  private lastChild(route: ActivatedRouteSnapshot): ActivatedRouteSnapshot {
    if (route.firstChild) {
      return this.lastChild(route.firstChild);
    } else {
      return route;
    }
  }
}
