import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'bari-section-menu',
  templateUrl: './bari-section-menu.component.html',
  styleUrls: ['./bari-section-menu.component.scss']
})
export class BariSectionMenuComponent implements OnInit {

  public isMobile: boolean;

  constructor(private _router: Router) { }

  public menuSection = [
    {
      name: 'Conta',
      href: '/account',
      icon: 'ic-navbar-account',
      flag: 'ACCOUNT'
    },
    // {
    //   name: 'Investimentos',
    //   href: './investiment',
    //   icon: 'ic-navbar-investment'
    // },
    {
      name: 'Cartões',
      href: '/cards',
      icon: 'ic-navbar-cards',
      flag: 'CARD'
    },
  ];

  ngOnInit() {
  }

  public logout() {
    sessionStorage.clear();
    location.reload();
  }

}
