import * as i0 from "./bari-toastr.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./bari-toastr.component";
import * as i4 from "ngx-toastr";
var styles_BariToastrComponent = [i0.styles];
var RenderType_BariToastrComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BariToastrComponent, data: { "animation": [{ type: 7, name: "flyInOut", definitions: [{ type: 0, name: "inactive", styles: { type: 6, styles: { opacity: 0 }, offset: null }, options: undefined }, { type: 1, expr: "inactive => active", animation: { type: 4, styles: { type: 5, steps: [{ type: 6, styles: { transform: "translate(0, -10%)", opacity: 0 }, offset: null }, { type: 6, styles: { transform: "translate(0, 0%)", opacity: 1 }, offset: null }] }, timings: "400ms linear" }, options: null }, { type: 1, expr: "active => removed", animation: { type: 4, styles: { type: 5, steps: [{ type: 6, styles: { opacity: 1 }, offset: null }, { type: 6, styles: { transform: "translate(0, -10%)", opacity: 0 }, offset: null }] }, timings: "400ms linear" }, options: null }], options: {} }] } });
export { RenderType_BariToastrComponent as RenderType_BariToastrComponent };
function View_BariToastrComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [], [[8, "className", 0], [1, "aria-label", 0]], null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.options.titleClass; var currVal_1 = _co.title; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = _co.title; _ck(_v, 1, 0, currVal_2); }); }
function View_BariToastrComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["aria-live", "polite"], ["role", "alert"]], [[8, "className", 0], [8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.options.messageClass; var currVal_1 = _co.message; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_BariToastrComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["aria-live", "polite"], ["role", "alert"]], [[8, "className", 0], [1, "aria-label", 0]], null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.options.messageClass; var currVal_1 = _co.message; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = _co.message; _ck(_v, 1, 0, currVal_2); }); }
export function View_BariToastrComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "row"]], [[4, "display", null]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "col-xs-10 alert-content"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BariToastrComponent_1)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BariToastrComponent_2)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BariToastrComponent_3)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 1, "div", [["class", "col-xs-2 text-right alert-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 0, "button", [["aria-label", "Close"], ["class", "toast-close-button close-button ng-star-inserted"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.remove() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.title; _ck(_v, 3, 0, currVal_1); var currVal_2 = (_co.message && _co.options.enableHtml); _ck(_v, 5, 0, currVal_2); var currVal_3 = (_co.message && !_co.options.enableHtml); _ck(_v, 7, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.state.value === "inactive") ? "none" : ""); _ck(_v, 0, 0, currVal_0); }); }
export function View_BariToastrComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "bari-toastr", [], [[8, "className", 0], [40, "@flyInOut", 0], [4, "display", null]], [[null, "click"], [null, "mouseenter"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).tapToast() !== false);
        ad = (pd_0 && ad);
    } if (("mouseenter" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).stickAround() !== false);
        ad = (pd_1 && ad);
    } if (("mouseleave" === en)) {
        var pd_2 = (i1.ɵnov(_v, 1).delayedHideToast() !== false);
        ad = (pd_2 && ad);
    } return ad; }, View_BariToastrComponent_0, RenderType_BariToastrComponent)), i1.ɵdid(1, 245760, null, 0, i3.BariToastrComponent, [i4.ToastrService, i4.ToastPackage], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).toastClasses; var currVal_1 = i1.ɵnov(_v, 1).state; var currVal_2 = i1.ɵnov(_v, 1).displayStyle; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
var BariToastrComponentNgFactory = i1.ɵccf("bari-toastr", i3.BariToastrComponent, View_BariToastrComponent_Host_0, {}, {}, []);
export { BariToastrComponentNgFactory as BariToastrComponentNgFactory };
