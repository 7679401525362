import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { Logger } from '../logger.service';
import { Router } from '@angular/router';
import { Credentials } from '../authentication/authentication.service';

const log = new Logger('Token Interceptor');

/**
 * Set Authorization for all requests.
 */
@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(private _router: Router) { }

  /**
  * Gets the user credentials.
  * @return {Credentials} The user credentials or null if the user is not authenticated.
  */
  get _credentials(): Credentials | null {
    log.debug('get _credentials()');
    return JSON.parse(sessionStorage.getItem('credentials') || localStorage.getItem('credentials'));
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // const credentials: Credentials = JSON.parse(sessionStorage.getItem('credentials'));
    log.debug('Credentials', this._credentials);

    if (this._credentials) {
      request = request.clone({
        setHeaders: {
          'Authorization': `Bearer ${this._credentials.token.access_token}`
        }
      });
    } else if (
      this._router.url.indexOf('login') === -1 &&
      this._router.url.indexOf('reset-password') === -1 &&
      this._router.url.indexOf('validate-token') === -1 &&
      this._router.url.indexOf('new-password') === -1
    ) {

      // this._router.navigate(['/logout']);
      window.location.reload();
      // throw new Error('Você não esta logado!');
    }

    return next.handle(request).pipe(
      tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          // do stuff with response if you want
        }
      }, (err: any) => {
        if (err instanceof HttpErrorResponse) {
          log.debug('Status Code', err.status);
          if (this._router.url !== '/login' && err.status === 401) {
            sessionStorage.removeItem('credentials');
            this._router.navigate(['/login/logout']);
            // redirect to the login route
            // or show a modal
          }
        }
      })
    );
  }
}
