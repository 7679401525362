import { Component, OnInit, OnChanges } from '@angular/core';
import { Router } from '@angular/router';
import { ShellHelper } from '@bari/shell/shell.helper';
import { Logger } from '@bari/core';
import { SwUpdate } from '@angular/service-worker';


const log = new Logger('Shell Component');

@Component({
  selector: 'bari-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss']
})
export class ShellComponent implements OnChanges, OnInit {

  public showHeader = true;
  private _ShellH = new ShellHelper (this.router);

  constructor(
    public router: Router,
    private update: SwUpdate
  ) {
    this.update.available.subscribe(() => window.location.reload())
  }

  private _toogleHader() {
    this.showHeader = this._ShellH.getRouteHeaderState();
    log.debug('_ShellH:', this._ShellH);
    log.debug('Show Menu?:', this.showHeader);
    if ( !this.showHeader ) {
      document.body.classList.add('no-header');
    } else {
      document.body.classList.remove('no-header');
    }
  }

  ngOnInit() {
    this._toogleHader();
  }

  ngOnChanges() {
    this._toogleHader();
  }
}
