import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { CustomersService } from '@bari/core/services/customers.service';
import { AuthenticationService } from '@bari/core';
import { WalletService } from '@bari/core/services/wallet.service';
import { ErrorService } from '@bari/core/error/services/error.service';
var ProfileComponent = (function () {
    function ProfileComponent(_service, _authService, _servicewallet, _errorService) {
        this._service = _service;
        this._authService = _authService;
        this._servicewallet = _servicewallet;
        this._errorService = _errorService;
        this.busy = false;
        this.busyProfileData = true;
    }
    ProfileComponent.prototype._getWallet = function () {
        var _this = this;
        return new Promise(function (resolve, reject) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var user, wallets, e_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.busy = false;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 4, , 5]);
                        return [4, this._service.getAllCustomerInformation(this._authService.credentials.username)];
                    case 2:
                        user = _a.sent();
                        this.user = user.id;
                        return [4, this._servicewallet.getWallet(this.user)];
                    case 3:
                        wallets = _a.sent();
                        this.agency = wallets.agency;
                        this.accountId = wallets.accountId;
                        this.busyProfileData = false;
                        resolve(this.agency);
                        return [3, 5];
                    case 4:
                        e_1 = _a.sent();
                        this._errorService.redirectErrorPage();
                        reject(e_1);
                        return [3, 5];
                    case 5: return [2];
                }
            });
        }); });
    };
    ProfileComponent.prototype.getName = function (firstLetter) {
        if (this.name) {
            if (firstLetter) {
                return this.name[0];
            }
            else {
                return this.name.split(' ')[0];
            }
        }
    };
    ProfileComponent.prototype.copyToClipboard = function () {
        var el = document.getElementById('clipBoard');
        el.setAttribute('contenteditable', 'true');
        el.focus();
        document.execCommand('selectAll');
        document.execCommand('copy');
        el.setAttribute('contenteditable', 'false');
        el.blur();
    };
    ProfileComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var user;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this._getWallet();
                        if (!this._authService.credentials) return [3, 2];
                        return [4, this._service.getAllCustomerInformation(this._authService.credentials.username)];
                    case 1:
                        user = _a.sent();
                        this.name = user.name;
                        this.email = user.mainEmailAddress;
                        this.phone = user.mainPhoneNumber;
                        _a.label = 2;
                    case 2: return [2];
                }
            });
        });
    };
    return ProfileComponent;
}());
export { ProfileComponent };
