import { Component, Input, OnInit, ElementRef, Renderer } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'bari-busy',
  templateUrl: './bari-busy.component.html',
  styleUrls: ['./bari-busy.component.scss'],
  entryComponents: []
})
export class BariBusyComponent implements OnInit {

  @Input() busy = false;
  @Input() absolute = false;

  constructor() { }

  ngOnInit() { }

}
