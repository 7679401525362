import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BariAvatarComponent } from '@shared/components/bari-avatar/bari-avatar.component';
import {BariButtonModule} from '@shared/components/bari-button/bari-button.module';
import {ClickOutsideDirective} from '@shared/directives/click-outside.directive';
import { RouterModule } from '@angular/router';
import { ProfileModule } from '@bari/modules/profile-module/profile.module';




@NgModule({
  imports: [
    CommonModule,
    BariButtonModule,
    RouterModule,
    ProfileModule
  ],
  declarations: [
    BariAvatarComponent,
    ClickOutsideDirective,
  ],
  exports: [BariAvatarComponent]
})
export class BariAvatarModule { }
