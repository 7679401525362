import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseModalService } from './shared/modal/services/base-modal.service';

@Component({
  selector: 'bari-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  private _title = 'Banco Bari';

  constructor(
    private _router: ActivatedRoute,
    private _routerService: Router,
    private _modal: BaseModalService
  ) {
  }

  ngOnInit() {
    // setTimeout(() => {
    //   this._modal.open('delay-alert-modal');
    // }, 2000);
    // console.warn(this._router);
    // this._router.events.subscribe((event) => {
    //   console.warn(event);
      document.title = this._title;
    // });
    // Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    // Add 'implements OnInit' to the class.
    // setTimeout(() => {
    //   this._routerService.navigateByUrl('/inactivity')
    // }, 600000);
  }
}
