import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCoffee, faAngleRight, faChevronDown, faAngleUp, faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { TooltipModule } from 'ng2-tooltip-directive';

// Add an icon to the library for convenient access in other components
library.add(faCoffee, faAngleRight, faChevronDown, faAngleUp);

// components
import { BariCardComponent } from './bari-card.component';
import { BariCollapsibleDirective } from '@shared/directives/collapsible.directive';
import { RouterModule } from '@angular/router';
import { BariLabelTitleDirective } from '@shared/directives/label-title.directive';
import { BariButtonModule } from '@shared/components/bari-button/bari-button.module';
import { BariAnimationModule } from '@shared/components/bari-animation/bari-animation.module';
import { BariBusyModule } from '../bari-busy/bari-busy.module';
import { BariLoadingFailedModule } from '@bari/shared/components/bari-loading-failed/bari-loading-failed.module';
@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FontAwesomeModule,
    TooltipModule,
    BariButtonModule,
    BariAnimationModule,
    BariBusyModule,
    BariLoadingFailedModule
  ],
  exports: [
    BariCardComponent
  ],
  declarations: [
    BariCardComponent,
    BariCollapsibleDirective,
    BariLabelTitleDirective
  ]
})
export class BariCardModule {
}
