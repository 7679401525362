import { of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Logger } from '../logger.service';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
var log = new Logger('Authentication Service');
var credentialsKey = 'credentials';
var AuthenticationService = (function () {
    function AuthenticationService(http, _router) {
        this.http = http;
        this._router = _router;
        var savedCredentials = sessionStorage.getItem(credentialsKey) || localStorage.getItem(credentialsKey);
        if (savedCredentials) {
            this._credentials = JSON.parse(savedCredentials);
        }
    }
    AuthenticationService.prototype._getFormUrlEncoded = function (toConvert) {
        var formBody = [];
        for (var _i = 0, _a = Object.keys(toConvert); _i < _a.length; _i++) {
            var property = _a[_i];
            var encodedKey = encodeURIComponent(property);
            var encodedValue = encodeURIComponent(toConvert[property]);
            formBody.push(encodedKey + '=' + encodedValue);
        }
        return formBody.join('&');
    };
    AuthenticationService.prototype.login = function (context) {
        var _this = this;
        log.debug('call login()', context);
        context.username = context.cpf;
        delete context.cpf;
        context.client_id = environment.client_id;
        context.client_secret = environment.client_secret;
        context.grant_type = 'password';
        return new Promise(function (resolve, reject) {
            _this.http.post('{api}/auth/connect/token', _this._getFormUrlEncoded(context), {
                headers: { 'content-type': 'application/x-www-form-urlencoded' }
            }).subscribe(function (data) {
                if (data['error']) {
                    reject(data['error']);
                }
                resolve(_this._setCredentials(context, data));
            }, function (response) {
                reject(response.error);
            });
        });
    };
    AuthenticationService.prototype.logout = function () {
        log.debug('call logout()');
        this._setCredentials();
        return of(true);
    };
    AuthenticationService.prototype.isAuthenticated = function () {
        log.debug('call isAuthenticated()', this.credentials);
        if (!this.credentials) {
            this.logout();
        }
        return !!this.credentials;
    };
    Object.defineProperty(AuthenticationService.prototype, "credentials", {
        get: function () {
            var savedCredentials = sessionStorage.getItem(credentialsKey) || localStorage.getItem(credentialsKey);
            this._credentials = (savedCredentials) ? JSON.parse(savedCredentials) : null;
            log.debug('get credentials()', this._credentials);
            return this._credentials;
        },
        enumerable: true,
        configurable: true
    });
    AuthenticationService.prototype.getToken = function () {
        log.debug('call getToken()');
        return this._credentials.token.access_token;
    };
    AuthenticationService.prototype._setCredentials = function (credentials, token, remember) {
        if (credentials && token) {
            var storage = remember ? localStorage : sessionStorage;
            credentials = {
                token: token,
                username: credentials.username
            };
            sessionStorage.setItem('lastcpf', credentials.username);
            storage.setItem(credentialsKey, JSON.stringify(credentials));
        }
        else {
            sessionStorage.removeItem(credentialsKey);
            localStorage.removeItem(credentialsKey);
            credentials = null;
        }
        this._credentials = credentials;
        return this.credentials;
    };
    AuthenticationService.prototype.getLastCPF = function () {
        return sessionStorage.getItem('lastcpf');
    };
    AuthenticationService.prototype.postActivation = function (username, activationToken) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.http.post("{api}/auth/v1/accounts/" + username + "/activate", {
                accountId: username,
                activationToken: activationToken
            }).toPromise().then(function () {
                resolve();
            }, function (error) {
                reject(error);
            });
        });
    };
    AuthenticationService.prototype.postResetPassword = function (username, invite, newPassword) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.http.post("{api}/auth/v1/accounts/" + username + "/resetpassword", {
                accountId: username,
                resetToken: invite,
                newPassword: newPassword
            }).toPromise().then(function () {
                resolve();
            }, function (error) {
                reject(error);
            });
        });
    };
    AuthenticationService.prototype.postValidatePassword = function (username, password) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.http.post("{api}/auth/v1/accounts/" + username + "/validatepassword", {
                password: password,
            }).toPromise().then(function () {
                resolve();
            }, function (error) {
                reject(error);
            });
        });
    };
    AuthenticationService.prototype.postRecoverPassword = function (username) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.http.post("{api}/auth/v1/accounts/" + username + "/recoverpassword", {
                accountId: username,
                method: 0
            }).toPromise().then(function (email) { return resolve(email); }, function (error) { return reject(error.error); });
        });
    };
    return AuthenticationService;
}());
export { AuthenticationService };
