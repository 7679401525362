
<div *ngIf="refresh">
  <p>Oops! Não foi possível carregar as informações.</p>
  <div class="row">
    <bari-button
    border="all"
    color="dark"
    size="big"
    icon="redo"
    text="between"
    (click)="reloaderComponent()"
    >
    Tentar novamente
    </bari-button>
  </div>
</div>
