// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import env from './.env';
import { Logger } from '../app/core/logger.service';
import { logging } from 'protractor';

const log = new Logger('Environment');


export const environment = {
  production: false,
  version: env.npm_package_version + '-dev',
  serverUrl: 'https://api.dev.bancobari.com.br/cards/',
  api: 'https://api.dev.bancobari.com.br',
  defaultLanguage: 'pt',
  client_id: 'web',
  client_secret: 'w34n6ilAr',
  supportedLanguages: [
    'pt',
    'pt-BR',
    'en-US',
  ],
  features: {
    'esqueci-minha-senha': false,
    'aviso-viagem': true,
    'enviar-fatura-email': true,
    'section-menu': true,
    'contestacao-com-motivo': true,
    ACCOUNT: false,
    CARD: false
  }
};

/*
 * In development mode, for easier debugging, you can ignore zone related error
 * stack frames such as `zone.run`/`zoneDelegate.invokeTask` by importing the
 * below file. Don't forget to comment it out in production mode
 * because it will have a performance impact when errors are thrown
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
