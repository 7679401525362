import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';


import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faBars } from '@fortawesome/free-solid-svg-icons';
library.add(faBars);

import { ShellComponent } from './shell.component';
import { BariAvatarModule } from '@bari/shared/components/bari-avatar/bari-avatar.module';
// import { CoreModule } from '@bari/core';
import { BariHeaderModule } from './components/bari-header/bari-header.module';
import { BariFooterModule } from './components/bari-footer/bari-footer.module';
import { CreditCardService } from '@bari/core/services/credit-card.service';


@NgModule({
  imports: [
    CommonModule,
    // CoreModule,
    RouterModule,
    BariHeaderModule,
    BariAvatarModule,
    BariFooterModule,
    FontAwesomeModule,
    ServiceWorkerModule
  ],
  providers: [
    CreditCardService
  ],
  declarations: [
    ShellComponent,
  ],
  exports: [ShellComponent]
})
export class ShellModule { }
