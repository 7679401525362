import { Component, OnInit } from '@angular/core';
import { Toast, ToastPackage, ToastrService } from 'ngx-toastr';
import { animate, keyframes, state, style, transition, trigger } from '@angular/animations';

// TODO: Mover as animações para a pasta certa
@Component({
  selector: 'bari-toastr',
  templateUrl: './bari-toastr.component.html',
  styleUrls: ['./bari-toastr.component.scss'],
  animations: [
    trigger('flyInOut', [
      state('inactive', style({
        opacity: 0,
      })),
      transition('inactive => active', animate('400ms linear', keyframes([
        style({
          transform: 'translate(0, -10%)',
          opacity: 0,
        }),
        style({
          transform: 'translate(0, 0%)',
          opacity: 1,
        }),
      ]))),
      transition('active => removed', animate('400ms linear', keyframes([
        style({
          opacity: 1,
        }),
        style({
          transform: 'translate(0, -10%)',
          opacity: 0,
        }),
      ]))),
    ]),
  ],
  preserveWhitespaces: false,
})
export class BariToastrComponent extends Toast implements OnInit {

  constructor(protected toastrService: ToastrService, public toastPackage: ToastPackage) {
    super(toastrService, toastPackage);
  }

  ngOnInit() {
  }

  action(event: Event) {
    event.stopPropagation();
    this.toastPackage.triggerAction();
    return false;
  }
}
