import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router } from '@angular/router';
import { FeatureFlagService } from '../services/feature-flag.service';
import { AuthenticationService } from '../authentication/authentication.service';

@Injectable()
export class FeatureToggleGuard implements CanActivate {
  constructor(
    private _ffService: FeatureFlagService,
    private _route: Router,
    private _authService: AuthenticationService
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      if (this._authService.credentials) {
        await this._ffService.getBackendFeatureFlag();

        let path: any = route.routeConfig.path.toUpperCase();

        path === 'CARDS' && (path = 'CARD'); // Corrige a rota de cards

        const allow = this._ffService.getFeatureFlag(path);

        if (!allow) {
          this._route.navigate(['/profile'], { replaceUrl: true }); // TODO redireciona para profile caso não tenha conta
          resolve(false);
        }
      }

      resolve(true);
    });
  }
}
