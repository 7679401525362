import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {
  private _defaultUrlToRedirect = '/account'; // Default url to redirect error

  constructor(private _router: Router) {}

  getClientMessage(error: Error): string {
    if (!navigator.onLine) {
      return 'No Internet Connection';
    }
    return error.message ? error.message : error.toString();
  }

  getClientStack(error: Error): string {
    return error.stack;
  }

  getServerMessage(error: HttpErrorResponse): string {
    return error.message;
  }

  getServerStack(error: HttpErrorResponse): string {
    // handle stack trace
    return 'stack';
  }

  // Call this function to redirect to error page after api catch
  redirectErrorPage() {
    // default router account
    this._router.navigate(['/error'], {
      state: {
        oldUrl: this._router.url === '/error' ? this._defaultUrlToRedirect : this._router.url
      }
    });
  }
}
