<div class="footer-inner">

  <div style="text-align:center" class="logo">
    <img width="60" alt="Bari Logo" src="assets/images/logo-branco.svg">
  </div>

  <div class="copyright">
    {{date}} ©
    <a href="#">Banco Bari S.A</a>
  </div>

  <div class="footer-actions">
    <ul>
      <!-- <a><a href="#">Termos de uso</a></li> -->
      <li><a target="_blank" href="https://files.bancobari.com.br/Contratos/privacidade.pdf">Termos de privacidade</a></li>
      <li><a target="_blank" [href]="getEmail()">Contato</a></li>
    </ul>
  </div>
</div>
