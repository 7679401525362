import { Router } from '@angular/router';
import { Logger } from '@bari/core';
import { EventEmitter } from '@angular/core';
var log = new Logger('Base Modal Service');
var BaseModalService = (function () {
    function BaseModalService(_router) {
        this._router = _router;
        this._modalState = false;
        this.onClose = new EventEmitter();
    }
    BaseModalService.prototype._toogleModalState = function (url, forceClose) {
        log.debug('_toogleModalState()', 'Modal State Toogle - force a close state?:', forceClose ? 'yes' : 'no');
        if (!this._modalState && !forceClose) {
            log.debug("Navigating to " + url);
            this._router.navigate(['/', { outlets: { modal: [url] } }], { skipLocationChange: true });
        }
        else {
            this._router.navigate(['/', { outlets: { modal: null } }], { skipLocationChange: true });
        }
    };
    BaseModalService.prototype.open = function (url, context) {
        log.debug('open()', 'Open modal with context: ', context);
        this._context = context;
        this._toogleModalState(url);
    };
    BaseModalService.prototype.close = function () {
        log.debug('close()', 'Close modal');
        this._context = null;
        this._toogleModalState('', true);
        this.onClose.emit();
    };
    Object.defineProperty(BaseModalService.prototype, "context", {
        get: function () {
            return this._context;
        },
        enumerable: true,
        configurable: true
    });
    return BaseModalService;
}());
export { BaseModalService };
