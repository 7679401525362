import { Injectable } from '@angular/core';
import { IndividualConfig, ToastrService } from 'ngx-toastr';

export enum ToastrType {
  NORMAL, SUCCESS, WARNING, ERROR
}

@Injectable({
  providedIn: 'root'
})
export class BariToastrService {

  private readonly config: Partial<IndividualConfig>;

  constructor(private toastrService: ToastrService) {
    this.config = {
      toastClass: 'ngx-toastr bari-toastr'
    };
  }

  show(type: ToastrType, title: string, message?: string) {
    switch (type) {
      case ToastrType.NORMAL:
        const config = {
          toastClass: 'ngx-toastr bari-toastr info'
        };
        this.toastrService.show(message, title, config);
        break;
      case ToastrType.SUCCESS:
        this.toastrService.success(message, title, this.config);
        break;
      case ToastrType.WARNING:
        this.toastrService.warning(message, title, this.config);
        break;
      case ToastrType.ERROR:
        this.toastrService.error(message, title, this.config);
        break;

    }
  }
}
