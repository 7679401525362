import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Logger } from '../logger.service';
import { Router } from '@angular/router';
import { environment } from '@env/environment';

const log = new Logger('Authentication Service');

export interface Credentials {
  // Customize received credentials here
  username: string;
  token?: TokenInterface;
}

export interface LoginContext {
  cpf?: string;
  username?: string;
  password: string;
  client_id?: string;
  client_secret?: string;
  grant_type?: string;
}

export interface RecoverPassword {
  email: string;
}

export interface TokenInterface {
  access_token: string;
  expires_in: string;
  token_type: string;
}

const credentialsKey = 'credentials';

/**
 * Provides a base for authentication workflow.
 * The Credentials interface as well as login/logout methods should be replaced with proper implementation.
 */
@Injectable()
export class AuthenticationService {

  private _credentials: Credentials | null;

  constructor(private http: HttpClient, private _router: Router) {
    const savedCredentials = sessionStorage.getItem(credentialsKey) || localStorage.getItem(credentialsKey);
    if (savedCredentials) {
      this._credentials = JSON.parse(savedCredentials);
    }
  }

  private _getFormUrlEncoded(toConvert: object) {
    const formBody = [];
    for (const property of Object.keys(toConvert)) {
      const encodedKey = encodeURIComponent(property);
      const encodedValue = encodeURIComponent(toConvert[property]);
      formBody.push(encodedKey + '=' + encodedValue);
    }
    return formBody.join('&');
  }

  /**
   * Authenticates the user.
   * @param {LoginContext} context The login parameters.
   * @return {Observable<Credentials>} The user credentials.
   */
  login(context: any): Promise<Credentials> {
    log.debug('call login()', context);
    context.username = context.cpf;
    delete context.cpf;
    context.client_id = environment.client_id;
    context.client_secret = environment.client_secret;
    context.grant_type = 'password';

    return new Promise<Credentials>((resolve, reject) => {
      this.http.post('{api}/auth/connect/token', this._getFormUrlEncoded(context), {
        headers: { 'content-type': 'application/x-www-form-urlencoded' }
      }).subscribe(
        (data: TokenInterface) => {
          if (data['error']) {
            reject(data['error']);
          }
          resolve(
            this._setCredentials(context, data)
          );
        },
        response => {
          reject(response.error);
        });
    });

    // this._setCredentials(data, context.remember);
    // return of(data);
  }

  /**
   * Logs out the user and clear credentials.
   * @return {Observable<boolean>} True if the user was logged out successfully.
   */
  logout(): Observable<boolean> {
    // Customize credentials invalidation here
    log.debug('call logout()');
    this._setCredentials();
    // this._router.navigate(['/login'], { replaceUrl: true });
    return of(true);
  }

  /**
   * Checks is the user is authenticated.
   * @return {boolean} True if the user is authenticated.
   */
  isAuthenticated(): boolean {
    log.debug('call isAuthenticated()', this.credentials);
    if (!this.credentials) {
      this.logout();
    }
    return !!this.credentials;
  }

  /**
   * Gets the user credentials.
   * @return {Credentials} The user credentials or null if the user is not authenticated.
   */
  get credentials(): Credentials | null {

    const savedCredentials = sessionStorage.getItem(credentialsKey) || localStorage.getItem(credentialsKey);

    this._credentials = (savedCredentials) ? JSON.parse(savedCredentials) : null;

    log.debug('get credentials()', this._credentials);
    return this._credentials;
  }

  getToken(): string {
    log.debug('call getToken()');
    return this._credentials.token.access_token;
  }

  /**
   * Sets the user credentials.
   * The credentials may be persisted across sessions by setting the `remember` parameter to true.
   * Otherwise, the credentials are only persisted for the current session.
   * @param {Credentials=} credentials The user credentials.
   * @param {boolean=} remember True to remember credentials across sessions.
   */
  private _setCredentials(credentials?: Credentials, token?: TokenInterface, remember?: boolean): Credentials | null {

    if (credentials && token) {
      const storage = remember ? localStorage : sessionStorage;
      credentials = {
        token: token,
        username: credentials.username
      };
      sessionStorage.setItem('lastcpf', credentials.username);
      storage.setItem(credentialsKey, JSON.stringify(credentials));
    } else {
      sessionStorage.removeItem(credentialsKey);
      localStorage.removeItem(credentialsKey);
      credentials = null;
    }

    this._credentials = credentials;
    return this.credentials;
  }

  public getLastCPF() {
    return sessionStorage.getItem('lastcpf');
  }

  public postActivation(username: number, activationToken: number) {
    return new Promise<string>((resolve, reject) => {
      this.http.post(`{api}/auth/v1/accounts/${username}/activate`, {
        accountId: username,
        activationToken: activationToken
      }).toPromise().then(() => {
        resolve();
      }, (error) => {
        reject(error);
      });
    });
  }

  public postResetPassword(username: number, invite: number, newPassword: string) {
    return new Promise<string>((resolve, reject) => {
      this.http.post(`{api}/auth/v1/accounts/${username}/resetpassword`, {
        accountId: username,
        resetToken: invite,
        newPassword: newPassword
      }).toPromise().then(() => {
        resolve();
      }, (error) => {
        reject(error);
      });
    });
  }

  public postValidatePassword(username: number, password: string) {
    return new Promise<string>((resolve, reject) => {
      this.http.post(`{api}/auth/v1/accounts/${username}/validatepassword`, {
        password: password,
      }).toPromise().then(() => {
        resolve();
      }, (error) => {
        reject(error);
      });
    });
  }

  public postRecoverPassword(username: string): Promise<RecoverPassword> {
    return new Promise<any>((resolve, reject) => {
      this.http.post(`{api}/auth/v1/accounts/${username}/recoverpassword`, {
        accountId: username,
        method: 0
      }).toPromise().then(
        (email) => resolve(email),
        error => reject(error.error));
    });
  }
}
