import { Component } from '@angular/core';
import { FeatureFlagService } from '@bari/core/services/feature-flag.service';
import { Router } from '@angular/router';

@Component({
  selector: 'bari-header',
  templateUrl: './bari-header.component.html',
  styleUrls: ['./bari-header.component.scss']
})
export class BariHeaderComponent {

  constructor(private _featureService: FeatureFlagService, public router: Router) { }

  public getLogoLink() {
    if (this._featureService.getFeatureState('section-menu')) {
      return '/account/dashboard';
    } else {
      return '/cards/dashboard';
    }
  }

}
