import {  Input, Directive, ElementRef, AfterContentInit, HostListener, DoCheck } from '@angular/core';

@Directive({
  selector: '[bariCollapsible]'
})
export class BariCollapsibleDirective implements AfterContentInit, DoCheck {
  private collapsibleElement;

  constructor(private el: ElementRef ) {}

  @Input() bariCollapsible: string;

  @HostListener('click')
  onClick(btn) {
    this.collapsibleElement.classList.toggle('hidden');
  }

  ngDoCheck(): void {
    this.collapsibleElement = document.body.querySelector(this.bariCollapsible);
    this.collapsibleElement.classList.add('collapsible-animation');
  }

  ngAfterContentInit(): void {

    if (this.el.nativeElement.classList.contains('collapsible')) {

    }


  }

}
