<header>
  <span *ngIf="title" [ngClass]="{'ph-loading' : busyTitle}">{{title}}</span>
  <fa-icon *ngIf="information" icon="info-circle" [tooltip]="information" placement="bottom" tooltip-class="tooltip-dashboard" ></fa-icon>
  <div class="label" *ngIf="label">{{label}}</div>
  <bari-button
    *ngIf="action.show"
    (action)="runAction($event)"
    border="all"
    size="small"
    color="blank"
  >{{action.label}}</bari-button>

  <button *ngIf="collapsible" bariCollapsible="#collapsible" class="btn push-right btn-no-border">
    <fa-icon icon="chevron-down"></fa-icon>
  </button>
  <ng-content select=".bari-card-header"></ng-content>
</header>

<div *ngIf="busyContent" class="bari-card-loading">
  <div class="ph-loading bari-card-loading-space"></div>
</div>

<ng-content *ngIf="!refresh && !busyContent" select=".bari-card-body"></ng-content>

<footer *ngIf="!busyContent && !noFooter">
  <ng-content select=".bari-card-footer"></ng-content>
</footer>
<ng-content select=".bari-card-body"></ng-content>


<bari-busy [busy]="busy" [absolute]="true"></bari-busy>
<bari-loading-failed [refresh]="refresh" (action)="reload()"></bari-loading-failed>


