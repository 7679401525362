import * as i0 from "./bari-header.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../bari-section-menu/bari-section-menu.component.ngfactory";
import * as i3 from "../bari-section-menu/bari-section-menu.component";
import * as i4 from "@angular/router";
import * as i5 from "../bari-menu/bari-menu.component.ngfactory";
import * as i6 from "../bari-menu/bari-menu.component";
import * as i7 from "../../../core/authentication/authentication.service";
import * as i8 from "@angular/common";
import * as i9 from "../../../core/services/feature-flag.service";
import * as i10 from "@angular/common/http";
import * as i11 from "../../../shared/directives/feature-flag.directive";
import * as i12 from "../../../shared/components/bari-avatar/bari-avatar.component.ngfactory";
import * as i13 from "../../../shared/components/bari-avatar/bari-avatar.component";
import * as i14 from "../../../core/services/customers.service";
import * as i15 from "../bari-breadcrumb/bari-breadcrumb.component.ngfactory";
import * as i16 from "../bari-breadcrumb/bari-breadcrumb.component";
import * as i17 from "./bari-header.component";
var styles_BariHeaderComponent = [i0.styles];
var RenderType_BariHeaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BariHeaderComponent, data: {} });
export { RenderType_BariHeaderComponent as RenderType_BariHeaderComponent };
function View_BariHeaderComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "bari-section-menu", [["class", "bari-main-menu"]], null, null, null, i2.View_BariSectionMenuComponent_0, i2.RenderType_BariSectionMenuComponent)), i1.ɵdid(1, 114688, null, 0, i3.BariSectionMenuComponent, [i4.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
function View_BariHeaderComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "bari-menu", [], null, null, null, i5.View_BariMenuComponent_0, i5.RenderType_BariMenuComponent)), i1.ɵdid(2, 114688, null, 0, i6.BariMenuComponent, [i7.AuthenticationService, i4.Router], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
export function View_BariHeaderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "div", [["class", "header-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 12, "div", [["class", "header-inner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 9, "div", [["class", "header-actions"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 3, "div", [["style", "text-align:center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 5).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(5, 671744, null, 0, i4.RouterLinkWithHref, [i4.Router, i4.ActivatedRoute, i8.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 0, "img", [["alt", "Bari Logo"], ["src", "assets/images/logo-banco-bari.svg"], ["width", "80"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_BariHeaderComponent_1)), i1.ɵprd(512, null, i9.FeatureFlagService, i9.FeatureFlagService, [i10.HttpClient]), i1.ɵdid(9, 81920, null, 0, i11.BariFeatureFlagDirective, [i1.TemplateRef, i1.ViewContainerRef, i9.FeatureFlagService], { bariFlag: [0, "bariFlag"] }, null), (_l()(), i1.ɵeld(10, 0, null, null, 1, "bari-avatar", [], null, null, null, i12.View_BariAvatarComponent_0, i12.RenderType_BariAvatarComponent)), i1.ɵdid(11, 114688, null, 0, i13.BariAvatarComponent, [i14.CustomersService, i7.AuthenticationService, i4.Router], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BariHeaderComponent_2)), i1.ɵdid(13, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(14, 0, null, null, 2, "div", [["class", "header-inner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 1, "bari-breadcrumb", [], null, null, null, i15.View_BariBreadcrumbComponent_0, i15.RenderType_BariBreadcrumbComponent)), i1.ɵdid(16, 114688, null, 0, i16.BariBreadcrumbComponent, [i4.ActivatedRoute], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.getLogoLink(); _ck(_v, 5, 0, currVal_2); var currVal_3 = "section-menu"; _ck(_v, 9, 0, currVal_3); _ck(_v, 11, 0); var currVal_4 = (_co.router.url != "/profile"); _ck(_v, 13, 0, currVal_4); _ck(_v, 16, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 5).target; var currVal_1 = i1.ɵnov(_v, 5).href; _ck(_v, 4, 0, currVal_0, currVal_1); }); }
export function View_BariHeaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "bari-header", [], null, null, null, View_BariHeaderComponent_0, RenderType_BariHeaderComponent)), i1.ɵdid(1, 49152, null, 0, i17.BariHeaderComponent, [i9.FeatureFlagService, i4.Router], null, null)], null, null); }
var BariHeaderComponentNgFactory = i1.ɵccf("bari-header", i17.BariHeaderComponent, View_BariHeaderComponent_Host_0, {}, {}, []);
export { BariHeaderComponentNgFactory as BariHeaderComponentNgFactory };
