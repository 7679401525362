import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Logger } from '../logger.service';
import { AuthenticationService, Credentials } from '../authentication/authentication.service';

const log = new Logger('CustomersService');

export interface PhoneNumberList {
  id: string;
  type: string;
  fullNumber: string;
}

export interface DocumentList {
  id: string;
  type: string;
  number: string;
  issuer: string;
  issueDate: Date;
  issuingCountry: string;
  issuingState: string;
  originatingCountry: string;
  expiryDate: Date;
}

export interface AddressList {
  id: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  country: string;
  neighborhood: string;
  number: string;
  postalCode: string;
  state: string;
}

export interface OnboardingStatus {
  additionalProp1: Date;
  additionalProp2: Date;
  additionalProp3: Date;
}

export interface Customer {
  id: string;
  name: string;
  motherName: string;
  fatherName: string;
  maritalStatus: string;
  mainEmailAddress: string;
  emailAddressList: string[];
  phoneNumberList: PhoneNumberList[];
  mainPhoneNumber: string;
  fatcaType: string;
  crsType: string;
  gender: string;
  birthdate: Date;
  bornLocationCity: string;
  bornLocationState: string;
  bornLocationCountry: string;
  nationality: string;
  documentList: DocumentList[];
  incoming: number;
  totalAssets: number;
  mainAddressId: string;
  addressList: AddressList[];
  activationCode: string;
  activationCodeExpiredate: Date;
  active: boolean;
  onboardingStatus: OnboardingStatus;
  isElegibleForNewInvitationCode: boolean;
  hasNotBeenInvited: boolean;
}

@Injectable()
export class CustomersService {

  private _user: Credentials;

  constructor(private http: HttpClient) {
  }

  public getAllCustomerInformation(username: string): Promise<Customer> {

    return new Promise<Customer>((resolve, reject) => {
      this.http.cache().get(`{api}/customer/v1/customers/${username}`, {
        headers: { 'content-type': 'application/x-www-form-urlencoded' }
      }).toPromise().then(
        (data: Customer) => {
          resolve(data);
        },
        response => {
          reject(response.error);
        });
    });

  }

  public checkUsername(username: string) {

    return new Promise<any>((resolve, reject) => {
      this.http.get(`{api}/customer/v1/customers/${username}/checkuserinvited`, {
        headers: { 'content-type': 'application/x-www-form-urlencoded' }
      }).subscribe(
        data => {
          resolve(data['status']);
        },
        response => {
          reject(response.error);
        });
    });

  }

  public postAccessCodeValidation(username: string, code: string) {

    return new Promise<any>((resolve, reject) => {
      const formData = {
        code: code
      };
      this.http.post(`{api}/customer/v1/customers/${username}/accesscodevalidation`, formData, {
      }).subscribe(
        data => {
          resolve(data);
        },
        response => {
          reject(response.error);
        });
    });

  }

  public postCreateLead(username: string, email: string) {

    return new Promise<any>((resolve, reject) => {
      const formData = {
        document: username,
        email: email
      };
      this.http.post(`{api}/customer/v1/customers/${username}/createlead`, formData, {
      }).subscribe(
        data => {
          resolve(data);
        },
        response => {
          reject(response.error);
        });
    });

  }


  public putRefreshActivationCode(username: string) {

    return new Promise<any>((resolve, reject) => {

      this.http.put(`{api}/customer/v1/customers/${username}/refreshActivationCode`, {}, {
      }).toPromise().then(
        data => {
          resolve(data);
        },
        response => {
          reject(response.error);
        });
    });

  }
}
