import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProfileComponent } from './profile.component';
import { ProfileRoutingModule } from './profile-routing';
import { BariCardModule } from '@bari/shared/components/bari-card/bari-card.module';
import { NgxMaskModule } from 'ngx-mask';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { BariBusyModule } from '@bari/shared/components/bari-busy/bari-busy.module';


@NgModule({
  imports: [
    CommonModule,
    ProfileRoutingModule,
    BariCardModule,
    NgxMaskModule.forRoot(),
    AngularSvgIconModule,
    BariBusyModule
  ],
  providers: [
    ProfileComponent
  ],
  declarations: [
    ProfileComponent
  ],
  exports: [ProfileComponent]
})
export class ProfileModule { }
