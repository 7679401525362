import {
  Component,
  Input,
  OnChanges,
  ElementRef,
  Renderer,
  Renderer2,
  OnInit,
  Output
} from '@angular/core';
import { Router } from '@angular/router';
import { EventEmitter } from '@angular/core';

@Component({
  selector: 'bari-card',
  templateUrl: './bari-card.component.html',
  styleUrls: ['./bari-card.component.scss'],
  entryComponents: []
})
export class BariCardComponent implements OnChanges, OnInit {
  @Input() action = {
    label: false,
    destiny: false,
    show: false
  };
  @Input() callBack: Function;
  @Input() information = false;
  @Input() title = false;
  @Input() label = false;
  @Input() busy = false;
  @Input() busyTitle = false;
  @Input() busyContent = false;
  @Input() labelColor;
  @Input() margin: string;
  @Input() footer: string;
  @Input() noFooter: boolean;
  @Input() refresh = false;
  @Input() header;

  @Output() refreshCallBack = new EventEmitter();

  public collapsible = false;

  private _nativeElement: ElementRef['nativeElement'];

  public runAction($event: any) {
    if (typeof this.callBack === 'function') {
      this.callBack();
    }

    if (typeof this.action.destiny === 'object') {
      this._route.navigate(this.action.destiny);
    }
  }

  public reload() {
    this.refreshCallBack.emit();
  }

  private _addClassToCard(typeOfStyle: string, variantOfStyle: string) {
    if (/ /.test(variantOfStyle)) {
      // Check if has multiple variants for one style type
      variantOfStyle.split(' ').forEach(variant => {
        this.renderer.addClass(this._nativeElement, `card-${typeOfStyle}-${variant}`);
      });
    } else {
      this.renderer.addClass(this._nativeElement, `card-${typeOfStyle}-${variantOfStyle}`);
    }
  }

  constructor(private el: ElementRef, private renderer: Renderer2, private _route: Router) {
    this._nativeElement = this.el.nativeElement;
  }

  ngOnInit() {
    if (!this.title) {
      this.renderer.addClass(this.el.nativeElement, 'no-title');
    }

    if (this.margin) {
      this._addClassToCard('margin', this.margin);
    }

    if (this.label) {
      this.renderer.addClass(this.el.nativeElement, 'has-label');
    }

    if (this.labelColor === 'noliquid') {
      this.renderer.addClass(this.el.nativeElement, 'noliquid');
    }

    if (this.el.nativeElement.classList.contains('collapsible')) {
      this.collapsible = true;
    }
    if (this.header) {
      this._addClassToCard('header', this.header);
    }
    if (this.footer) {
      this._addClassToCard('footer', this.footer);
    }
  }

  // Listening change to set new value
  ngOnChanges(obj) {
    obj.hasOwnProperty('footer') && this._addClassToCard('footer', this.footer);
  }
}
