import * as i0 from "./bari-busy.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../bari-animation/bari-animation.component.ngfactory";
import * as i4 from "../bari-animation/bari-animation.component";
import * as i5 from "./bari-busy.component";
var styles_BariBusyComponent = [i0.styles];
var RenderType_BariBusyComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BariBusyComponent, data: {} });
export { RenderType_BariBusyComponent as RenderType_BariBusyComponent };
function View_BariBusyComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "busy"]], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(2, { "busy-absolute": 0 }), (_l()(), i1.ɵeld(3, 0, null, null, 1, "bari-animation", [["animation", "loading_bouncing_preto_big"]], null, null, null, i3.View_BariAnimationComponent_0, i3.RenderType_BariAnimationComponent)), i1.ɵdid(4, 114688, null, 0, i4.BariAnimationComponent, [], { animation: [0, "animation"], width: [1, "width"], loop: [2, "loop"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "busy"; var currVal_1 = _ck(_v, 2, 0, _co.absolute); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = "loading_bouncing_preto_big"; var currVal_3 = 120; var currVal_4 = true; _ck(_v, 4, 0, currVal_2, currVal_3, currVal_4); }, null); }
export function View_BariBusyComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_BariBusyComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.busy; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_BariBusyComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "bari-busy", [], null, null, null, View_BariBusyComponent_0, RenderType_BariBusyComponent)), i1.ɵdid(1, 114688, null, 0, i5.BariBusyComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BariBusyComponentNgFactory = i1.ɵccf("bari-busy", i5.BariBusyComponent, View_BariBusyComponent_Host_0, { busy: "busy", absolute: "absolute" }, {}, []);
export { BariBusyComponentNgFactory as BariBusyComponentNgFactory };
