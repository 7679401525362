import { NgModule } from '@angular/core';

import { BariHeaderComponent } from '@bari/shell/components/bari-header/bari-header.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { BariMenuModule } from '../bari-menu/bari-menu.module';
import { BariButtonModule } from '@bari/shared/components/bari-button/bari-button.module';
import { BariAvatarModule } from '@bari/shared/components/bari-avatar/bari-avatar.module';
import { CoreModule } from '@bari/core';
import { BariBreadcrumbModule } from '../bari-breadcrumb/bari-breadcrumb.module';
import { BariSectionMenuModule } from '../bari-section-menu/bari-section-menu-module';
import { RouterModule } from '@angular/router';
import { DirectiveModule } from '@bari/shared/directives/directive.module';
import { FeatureFlagService } from '@bari/core/services/feature-flag.service';
import { CommonModule } from '@angular/common';
library.add(faBars);

@NgModule({
  imports: [
    FontAwesomeModule,
    CoreModule,
    BariMenuModule,
    BariButtonModule,
    BariAvatarModule,
    BariBreadcrumbModule,
    BariSectionMenuModule,
    RouterModule,
    DirectiveModule,
    CommonModule
  ],
  providers: [
    FeatureFlagService
  ],
  declarations: [
    BariHeaderComponent,
  ],
  exports: [BariHeaderComponent]
})
export class BariHeaderModule { }
