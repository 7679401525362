import * as i0 from "./bari-loading-failed.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../bari-button/bari-button.component.ngfactory";
import * as i3 from "../bari-button/bari-button.component";
import * as i4 from "@angular/common";
import * as i5 from "./bari-loading-failed.component";
var styles_BariLoadingFailedComponent = [i0.styles];
var RenderType_BariLoadingFailedComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BariLoadingFailedComponent, data: {} });
export { RenderType_BariLoadingFailedComponent as RenderType_BariLoadingFailedComponent };
function View_BariLoadingFailedComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Oops! N\u00E3o foi poss\u00EDvel carregar as informa\u00E7\u00F5es."])), (_l()(), i1.ɵeld(3, 0, null, null, 3, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "bari-button", [["border", "all"], ["color", "dark"], ["icon", "redo"], ["size", "big"], ["text", "between"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.reloaderComponent() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_BariButtonComponent_0, i2.RenderType_BariButtonComponent)), i1.ɵdid(5, 638976, null, 0, i3.BariButtonComponent, [i1.ElementRef, i1.Renderer2], { icon: [0, "icon"], color: [1, "color"], text: [2, "text"], border: [3, "border"], size: [4, "size"] }, null), (_l()(), i1.ɵted(-1, 0, [" Tentar novamente "]))], function (_ck, _v) { var currVal_0 = "redo"; var currVal_1 = "dark"; var currVal_2 = "between"; var currVal_3 = "all"; var currVal_4 = "big"; _ck(_v, 5, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }, null); }
export function View_BariLoadingFailedComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_BariLoadingFailedComponent_1)), i1.ɵdid(1, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.refresh; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_BariLoadingFailedComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "bari-loading-failed", [], null, null, null, View_BariLoadingFailedComponent_0, RenderType_BariLoadingFailedComponent)), i1.ɵdid(1, 114688, null, 0, i5.BariLoadingFailedComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BariLoadingFailedComponentNgFactory = i1.ɵccf("bari-loading-failed", i5.BariLoadingFailedComponent, View_BariLoadingFailedComponent_Host_0, { refresh: "refresh" }, { action: "action" }, []);
export { BariLoadingFailedComponentNgFactory as BariLoadingFailedComponentNgFactory };
