import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faBars } from '@fortawesome/free-solid-svg-icons';
library.add(faBars);

import { BariSectionMenuComponent } from '@bari/shell/components/bari-section-menu/bari-section-menu.component';
import { RouterModule } from '@angular/router';
import { CoreModule } from '@bari/core';
import { BariButtonModule } from '@bari/shared/components/bari-button/bari-button.module';
import { DirectiveModule } from '@bari/shared/directives/directive.module';

@NgModule({
  imports: [
    CommonModule,
    CoreModule,
    RouterModule,
    DirectiveModule,
    FontAwesomeModule,
    BariButtonModule
  ],
  declarations: [
    BariSectionMenuComponent
  ],
  exports: [BariSectionMenuComponent]
})
export class BariSectionMenuModule { }
