import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
// Add an icon to the library for convenient access in other components

// components
import { BariAnimationComponent } from './bari-animation.component';
import { LottieAnimationViewModule } from 'ng-lottie';

@NgModule({
  imports: [
    CommonModule,
    LottieAnimationViewModule
  ],
  exports: [
    BariAnimationComponent
  ],
  declarations: [
    BariAnimationComponent,
  ]
})
export class BariAnimationModule {
}
