<div class="header-container">
  <div class="header-inner">

    <div class="header-actions">

      <div style="text-align:center">
        <a [routerLink]="getLogoLink()">
          <img width="80" alt="Bari Logo" src="assets/images/logo-banco-bari.svg">
        </a>
      </div>

      <bari-section-menu
        *bariFlag="'section-menu'"
        class="bari-main-menu"
      >
      </bari-section-menu>

      <bari-avatar></bari-avatar>

    </div>

    <div *ngIf="router.url != '/profile'">
      <bari-menu ></bari-menu>
    </div>

  </div>
</div>
<div class="header-inner">
  <bari-breadcrumb></bari-breadcrumb>
</div>
