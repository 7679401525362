import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'bari-animation',
  templateUrl: './bari-animation.component.html',
  styleUrls: ['./bari-animation.component.scss'],
  entryComponents: []
})
export class BariAnimationComponent implements OnInit {

  @Input() animation: string;
  @Input() width = 250;
  @Input() loop = false;
  @Input() autoplay = true;
  @Input()
  set setFrame(frames: { start?: number, end: number, final?: number; }) {
    if (frames && frames.start) {
      this.setAnimationFrameLoop(frames.start, frames.end);
    }
    if (frames && frames.final) {
      this.setAnimationFrameLoop(null, frames.end, frames.final);
    }
  }

  public configuration: Object;
  private anim: any;

  constructor() { }

  public animCreated($event) {
    this.anim = $event;
  }

  public animationEvent($event) {
    console.warn($event);
  }

  public setAnimationFrameLoop(start?: number, end?: number, final?: number) {
    if (this.anim && start) {
      this.anim.playSegments([[0, start], [start, end]], true);
    }
    if (this.anim && final) {
      this.anim.setSpeed(2);
      this.anim.playSegments([[end, final]], false);
      this.anim.loop = false;
    }
  }

  ngOnInit() {
    this.configuration = {
      path: `assets/images/animations/${this.animation}.json`,
      renderer: 'svg',
      autoplay: this.autoplay,
      loop: this.loop,
      rendererSettings: {
        // preserveAspectRatio: 'xMidYMin meet',
      }
    };
  }

}
