import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Add an icon to the library for convenient access in other components

// components
import { BariBusyComponent } from './bari-busy.component';
import { BariAnimationModule } from '@shared/components/bari-animation/bari-animation.module';

@NgModule({
  imports: [
    CommonModule,
    BariAnimationModule,
  ],
  exports: [
    BariBusyComponent
  ],
  declarations: [
    BariBusyComponent,
  ]
})
export class BariBusyModule {
}
