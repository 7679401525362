import { Routes } from '@angular/router';
import { ProfileComponent } from './profile.component';
var ɵ0 = {
    title: 'Profile',
};
var routes = [
    { path: '', redirectTo: 'profile', pathMatch: 'full' },
    {
        path: '',
        component: ProfileComponent,
        data: ɵ0,
    }
];
var ProfileRoutingModule = (function () {
    function ProfileRoutingModule() {
    }
    return ProfileRoutingModule;
}());
export { ProfileRoutingModule };
export { ɵ0 };
