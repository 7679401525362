import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FeatureFlagService } from '@bari/core/services/feature-flag.service';
import { BariFeatureFlagDirective } from './feature-flag.directive';


@NgModule({
  imports: [
    CommonModule
  ],
  providers: [],
  declarations: [
    BariFeatureFlagDirective
  ],
  exports: [BariFeatureFlagDirective]
})
export class DirectiveModule { }
