import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faAngleRight, faCircle } from '@fortawesome/free-solid-svg-icons';
import { TooltipModule } from 'ng2-tooltip-directive';

// Add an icon to the library for convenient access in other components
library.add(faAngleRight, faCircle);

// components
import { BariButtonComponent } from '@shared/components/bari-button/bari-button.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { BariAnimationModule } from '../bari-animation/bari-animation.module';

@NgModule({
  imports: [
    CommonModule,
    FontAwesomeModule,
    TooltipModule,
    AngularSvgIconModule,
    BariAnimationModule
  ],
  exports: [
    BariButtonComponent
  ],
  declarations: [
    BariButtonComponent
  ]
})
export class BariButtonModule {
}
