<button
  [type]="type"
  class="btn {{class}}"
  [ngClass]="{'btn-disabled': disabled}"
  [disabled]="validDisabled()"
  (click)="click()"
>
  <div class="btn-content" *ngIf="!busy" [@enterAnimation]>
    <span>
      <ng-content></ng-content>
    </span>

    <svg-icon
      *ngIf="icon && !iconsIsAnimation && !busy"
      class="icon"
      [applyCss]="'busy'"
      [src]='finalIcon()'
      [svgStyle]="{ 'width.px': iconSize, 'fill': iconColor }"
    ></svg-icon>
  </div>

  <bari-animation
    *ngIf="busy"
    class="busy"
    [animation]="busyIcon"
    [autoplay]="true"
    [width]="iconSize"
    [loop]="true"
    [@enterAnimation]
  ></bari-animation>
</button>
