import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'bari-breadcrumb',
  templateUrl: './bari-breadcrumb.component.html',
  styleUrls: ['./bari-breadcrumb.component.scss']
})
export class BariBreadcrumbComponent implements OnInit {
  public items: object;

  constructor(private _router: ActivatedRoute) { }

  ngOnInit() {
    const items = [];
    this._router.url.subscribe(() => {
      this.items = this._router.snapshot.firstChild.data.breadcumb;
      // console.warn(this._router.snapshot.firstChild.data.breadcumb);
    });
  }

}
