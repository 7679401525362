import { Input, Directive, ElementRef, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { FeatureFlagService } from '@bari/core/services/feature-flag.service';

@Directive({
  selector: '[bariFlag]',
  providers: [FeatureFlagService]
})
export class BariFeatureFlagDirective implements OnInit {

  @Input() bariFlag: string;

  constructor(
    private tpl: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private service: FeatureFlagService
    ) { }

    private _showFlag() {
      if (this.service.getFeatureState(this.bariFlag)) {
        this.viewContainer.createEmbeddedView(this.tpl);
      } else {
        this.viewContainer.clear();
      }
    }

  ngOnInit() {
    this._showFlag();
  }

}
