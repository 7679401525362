import { NgModule } from '@angular/core';

import { BariFooterComponent } from '@bari/shell/components/bari-footer/bari-footer.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { BariMenuModule } from '../bari-menu/bari-menu.module';
import { BariButtonModule } from '@bari/shared/components/bari-button/bari-button.module';
import { BariAvatarModule } from '@bari/shared/components/bari-avatar/bari-avatar.module';
import { CoreModule } from '@bari/core';
import { BariBreadcrumbModule } from '../bari-breadcrumb/bari-breadcrumb.module';
library.add(faBars);

@NgModule({
  imports: [
    FontAwesomeModule,
    CoreModule,
    BariMenuModule,
    BariButtonModule,
    BariAvatarModule,
    BariBreadcrumbModule
  ],
  declarations: [
    BariFooterComponent,
  ],
  exports: [BariFooterComponent]
})
export class BariFooterModule { }
