import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '@bari/core';
import { Router } from '@angular/router';

@Component({
  selector: 'bari-menu',
  templateUrl: './bari-menu.component.html',
  styleUrls: ['./bari-menu.component.scss']
})
export class BariMenuComponent implements OnInit {

  constructor(private _service: AuthenticationService, private _router: Router) { }

  public menuItens;

  public menuDefault = {
    cards: [
    {
      name: 'Visão Geral',
      href: '/cards/dashboard'
    },
    {
      name: 'Extrato e dependentes',
      href: '/cards/statement'
    },
  ],
  account: [
    {
      name: 'Visão Geral',
      href: '/account/dashboard'
    },
    // {
    //   name: 'Objetivos',
    //   href: '/account'
    // },
    {
      name: 'Extrato',
      href: '/account/statement'
    },
  ]
};


public isRoutingPage() {
  if (this._router.url.includes('/cards')) {
    this.menuItens = this.menuDefault['cards'];
  } else {
    this.menuItens = this.menuDefault['account'];
  }
}


  public logout() {
    this._service.logout().subscribe(
      result => {
        if (result) {
          sessionStorage.removeItem('lastcpf');
          this._router.navigate(['/login']);
        }
      }
    );
  }

  ngOnInit() {
    this.isRoutingPage();
  }

}
