
<div class="row">

  <div class="col-md-6 col-xs-12 text">

    <div>
      <div class="row title-profile">
        <span>Olá,</span>
        <span class="name" >&nbsp;{{getName()}}</span>
        <div class="pointer"></div>
      </div>
      <p>Veja os dados da sua conta Bari.</p>
      <p>Alterações são permitidas apenas pelo aplicativo.</p>
    </div>

  </div>

  <div class="col-md-6 col-xs-12">
    <bari-card
    [busyContent]="busyProfileData"
    class="no-w-padding info-profile"
    >
    <div class="bari-card-body">

      <div class="data-account">

        <div class="title-info">
          <h4>Minha conta</h4>
          <h5>Cód. 914</h5>
        </div>

        <div class="content">

          <div id='clipBoard'>

            <div class="row">
              <h6>Agência</h6>
              <span>{{agency}}</span>
            </div>

            <div class="row">
              <h6>Conta</h6>
              <span>{{accountId}}</span>
            </div>

          </div>

          <div class="clip-board">
            <svg-icon (click)="copyToClipboard()" src="assets/images/icons/copy-alt.svg" [svgStyle]="{ 'width.px':20, 'fill': '#000' }" alt="Copiar" > </svg-icon>
          </div>

        </div>

      </div>

      <div class="profile-data">

        <div class="title-info">
          <h4>Meus Dados</h4>
        </div>

        <div class="data">

          <div>
            <h6>Nome completo</h6>
            <span>{{name}}</span>
          </div>

          <div>
            <h6>E-mail</h6>
            <span>{{(email)}}</span>

          </div>

          <div>
            <h6>Telefone</h6>
            <span>{{phone | mask: '+00 (00) 00000-0000'}}</span>
          </div>

        </div>

      </div>

    </div>
  </bari-card>

</div>

</div>
