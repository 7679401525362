import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {AuthenticationService} from '@bari/core';
import { CustomersService } from '@bari/core/services/customers.service';
import { ButtonAnimation } from '@bari/shared/animations/button.animation';
import { Router } from '@angular/router';

@Component({
  selector: 'bari-avatar',
  templateUrl: './bari-avatar.component.html',
  styleUrls: ['./bari-avatar.component.scss'],
  animations: ButtonAnimation.animations
})
export class BariAvatarComponent implements OnInit {

  @ViewChild('submenu') submenu: ElementRef;

  public avatar;
  public name: string;
  public menuIsOpen = false;

  constructor(private _service: CustomersService, private _authService: AuthenticationService, private _router: Router) {
    // this.avatar = 'https://via.placeholder.com/150/0090FF/FFFFFF?Text=R';
  }

  async ngOnInit() {
    if (this._authService.credentials) {
      const user = await this._service.getAllCustomerInformation(this._authService.credentials.username);
      this.name = user.name;
    }
  }

  public logout() {
    sessionStorage.clear();
    location.reload();
  }

  public  profile() {

  }

  public toggleSubmenu() {
    const e = (this.submenu.nativeElement as HTMLElement);
    if (this.menuIsOpen) {
      e.classList.remove('opened');
      e.classList.add('closed');
      this.menuIsOpen = false;
    } else {
      e.classList.add('opened');
      e.classList.remove('closed');
      this.menuIsOpen = true;
    }
  }

  public getName(firstLetter?: boolean) {
    if (this.name) {
      if (firstLetter) {
        return this.name[0];
      } else {
        return this.name.split(' ')[0];
      }
    }
  }

  public verifyMenu() {
    return;

    // TODO: Arrumar essa implementação, ela não abre o menu às vezes
    // if (this.menuIsOpen) {
    //   const e = (this.submenu.nativeElement as HTMLElement);
    //   e.classList.remove('opened');
    //   e.classList.add('closed');
    //   this.menuIsOpen = false;
    // }
  }
}
