<div class="avatar-content" clickOutside (clickOutside)="verifyMenu()" (click)="toggleSubmenu()">
  <div class="avatar-name">
    <bari-button [icon]="menuIsOpen ? 'angle-up' : 'angle-down'"></bari-button>
    <span class="welcome">Olá,</span>
    <span class="name">&nbsp;{{getName()}}</span>
  </div>

  <div class="avatar-image">
    <img src="{{avatar}}" alt="Foto de Perfil de {{getName()}}" *ngIf="avatar" />
    <span class="no-image" *ngIf="!avatar">{{ getName(true) }}</span>
  </div>

  <div class="submenu closed" #submenu [@enterAnimation]>
    <ul>
      <li>
        <bari-button  class="btn"
          color="transparent"
          direction="row-reverse"
          size="medium"
          text="left"
          [routerLink]="['/profile']"
          [routerLinkActive]="['active']"
        >
          Meu Perfil
        </bari-button>
      </li>
      <li class="row"></li>
      <li>
        <bari-button
          color="transparent"
          direction="row-reverse"
          size="medium"
          text="left"
          (action)="logout()"
        >
          Sair
        </bari-button>
      </li>
    </ul>
  </div>

</div>
