import { OnInit } from '@angular/core';
var BariAnimationComponent = (function () {
    function BariAnimationComponent() {
        this.width = 250;
        this.loop = false;
        this.autoplay = true;
    }
    Object.defineProperty(BariAnimationComponent.prototype, "setFrame", {
        set: function (frames) {
            if (frames && frames.start) {
                this.setAnimationFrameLoop(frames.start, frames.end);
            }
            if (frames && frames.final) {
                this.setAnimationFrameLoop(null, frames.end, frames.final);
            }
        },
        enumerable: true,
        configurable: true
    });
    BariAnimationComponent.prototype.animCreated = function ($event) {
        this.anim = $event;
    };
    BariAnimationComponent.prototype.animationEvent = function ($event) {
        console.warn($event);
    };
    BariAnimationComponent.prototype.setAnimationFrameLoop = function (start, end, final) {
        if (this.anim && start) {
            this.anim.playSegments([[0, start], [start, end]], true);
        }
        if (this.anim && final) {
            this.anim.setSpeed(2);
            this.anim.playSegments([[end, final]], false);
            this.anim.loop = false;
        }
    };
    BariAnimationComponent.prototype.ngOnInit = function () {
        this.configuration = {
            path: "assets/images/animations/" + this.animation + ".json",
            renderer: 'svg',
            autoplay: this.autoplay,
            loop: this.loop,
            rendererSettings: {}
        };
    };
    return BariAnimationComponent;
}());
export { BariAnimationComponent };
