<nav *bariFlag="'section-menu'">

  <ul class="menu-block">
    <li *ngFor="let item of menuSection" >
      <bari-button
        class="btn"
        color="transparent"
        direction="row-reverse"
        size="medium"
        [routerLink]="[item.href]"
        [icon]="[item.icon]"
        *bariFlag="item.flag"
        [routerLinkActive]="['active']" >
        {{item.name}}
      </bari-button>
    </li>
  </ul>

  </nav>
