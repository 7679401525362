import { Injectable } from '@angular/core';
import { Logger } from '../logger.service';
import { environment } from '@env/environment';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';

const log = new Logger('Feature Flag');

@Injectable()
export class FeatureFlagService {

  private features = environment.features;

  constructor(private http: HttpClient) { }

  public getFeatureState(feature: string) {
    if (feature in this.features) {
      return this.features[feature];
    }
    return true;
  }

  public getFeatureFlag(flag: string) {
    return this.features[flag] || false;
  }

  public _setFeatureState(flags: []) {
    flags.map((flag: any) => {
      this.features[flag.code] = flag.isActive;
    });
  }

  public getBackendFeatureFlag(): Promise<[]> {
    return new Promise<[]>((resolve, reject) => {
      this.http
        .cache()
        .get('{api}/featureflag/v1/customerScope')
        .toPromise()
        .then(
          (data: []) => {
            this._setFeatureState(data);
            resolve();
          },
          error => {
            reject(error.error);
          }
        );
    });
  }
}
