import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '@bari/core';

@Component({
  selector: 'bari-footer',
  templateUrl: './bari-footer.component.html',
  styleUrls: ['./bari-footer.component.scss']
})
export class BariFooterComponent implements OnInit {

  public date = new Date().getFullYear();
  private _mail: string;

  constructor(private _auth: AuthenticationService) {
    this._mail = (this._auth.isAuthenticated()) ? 'mailto:ajuda@bancobari.com.br' : 'mailto:cadastro@bancobari.com.br';
  }

  public getEmail() {
    return this._mail;
  }

  ngOnInit() { }

}
