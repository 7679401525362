import { Component, OnInit, Input, Output, EventEmitter  } from '@angular/core';

@Component({
  selector: 'bari-loading-failed',
  templateUrl: './bari-loading-failed.component.html',
  styleUrls: ['./bari-loading-failed.component.scss'],
  entryComponents: []
})
export class BariLoadingFailedComponent implements OnInit {

  @Input() refresh = false;
  @Output() action = new EventEmitter();


  constructor() { }


  public reloaderComponent() {
    this.action.emit();
  }

  ngOnInit() {
  }

}
