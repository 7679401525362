import * as tslib_1 from "tslib";
import { InjectionToken, Injector } from '@angular/core';
import { HttpClient, HttpHandler } from '@angular/common/http';
import { ErrorHandlerInterceptor } from './error-handler.interceptor';
import { CacheInterceptor } from './cache.interceptor';
import { ApiPrefixInterceptor } from './api-prefix.interceptor';
import { TokenInterceptor } from './token.interceptor';
var HttpInterceptorHandler = (function () {
    function HttpInterceptorHandler(next, interceptor) {
        this.next = next;
        this.interceptor = interceptor;
    }
    HttpInterceptorHandler.prototype.handle = function (request) {
        return this.interceptor.intercept(request, this.next);
    };
    return HttpInterceptorHandler;
}());
export var HTTP_DYNAMIC_INTERCEPTORS = new InjectionToken('HTTP_DYNAMIC_INTERCEPTORS');
var HttpService = (function (_super) {
    tslib_1.__extends(HttpService, _super);
    function HttpService(httpHandler, injector, interceptors) {
        if (interceptors === void 0) { interceptors = []; }
        var _this = _super.call(this, httpHandler) || this;
        _this.httpHandler = httpHandler;
        _this.injector = injector;
        _this.interceptors = interceptors;
        if (!_this.interceptors) {
            _this.interceptors = [
                _this.injector.get(ApiPrefixInterceptor),
                _this.injector.get(TokenInterceptor),
                _this.injector.get(ErrorHandlerInterceptor)
            ];
        }
        return _this;
    }
    HttpService.prototype.cache = function (forceUpdate) {
        var cacheInterceptor = this.injector.get(CacheInterceptor).configure({ update: forceUpdate });
        return this.addInterceptor(cacheInterceptor);
    };
    HttpService.prototype.skipErrorHandler = function () {
        return this.removeInterceptor(ErrorHandlerInterceptor);
    };
    HttpService.prototype.disableApiPrefix = function () {
        return this.removeInterceptor(ApiPrefixInterceptor);
    };
    HttpService.prototype.disableToken = function () {
        return this.removeInterceptor(TokenInterceptor);
    };
    HttpService.prototype.request = function (method, url, options) {
        var handler = this.interceptors.reduceRight(function (next, interceptor) { return new HttpInterceptorHandler(next, interceptor); }, this.httpHandler);
        return new HttpClient(handler).request(method, url, options);
    };
    HttpService.prototype.removeInterceptor = function (interceptorType) {
        return new HttpService(this.httpHandler, this.injector, this.interceptors.filter(function (i) { return !(i instanceof interceptorType); }));
    };
    HttpService.prototype.addInterceptor = function (interceptor) {
        return new HttpService(this.httpHandler, this.injector, this.interceptors.concat([interceptor]));
    };
    return HttpService;
}(HttpClient));
export { HttpService };
