import { Router } from '@angular/router';
import { Logger } from '@bari/core';
import { EventEmitter, Injectable } from '@angular/core';

const log = new Logger('Base Modal Service');

@Injectable()
export class BaseModalService {

  public onClose: EventEmitter<any>;

  private _context: any;
  private _modalState = false;

  constructor(private _router: Router) {
    this.onClose = new EventEmitter();
  }

  /**
   * Automatic check state of modal and change this.
   *
   * @param url location of modal
   * @param forceClose set True to allways force modal close
   */
  protected _toogleModalState(url: string, forceClose?: boolean) {

    log.debug('_toogleModalState()', 'Modal State Toogle - force a close state?:', forceClose ? 'yes' : 'no');

    if (!this._modalState && !forceClose) {
      log.debug(`Navigating to ${url}`);
      this._router.navigate(['/', {outlets: {modal: [url]}}], {skipLocationChange: true});
    } else {
      this._router.navigate(['/', {outlets: {modal: null}}], {skipLocationChange: true});
    }
  }

  /**
   * Open modal
   *
   * @param url where modal is located
   * @param context the data
   * */
  public open<T>(url: string, context?: T) {
    log.debug('open()', 'Open modal with context: ', context);
    this._context = context;
    this._toogleModalState(url);
  }

  /**
   * Close modal
   * */
  public close() {
    log.debug('close()', 'Close modal');
    this._context = null;
    this._toogleModalState('', true);
    this.onClose.emit();
  }

  public get context() {
    return this._context;
  }
}
