import { Injectable } from '@angular/core';
import { Router, CanActivate, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';


import { AuthenticationService } from './authentication.service';
import { Logger } from '../logger.service';

const log = new Logger('Authentication Guard');

@Injectable()
export class AuthenticationGuard implements CanActivate {

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
    // Check if use are authenticated
    if (this.authenticationService.isAuthenticated()) {
      // if authenticated and are in login page, redirect the user to dashboard page
      if (state.url === '/login') {
        this.router.navigate(['/dashboard'], { replaceUrl: true });
      }

      return true;
    }

    // If not authenticated and are in login page, stay here.
    if (
      state.url === '/login' ||
      state.url === '/new-password'
    ) {
      return true;
    }

    // else send the user to login page
    log.debug('Not authenticated, redirecting...');
    this.router.navigate(['/login'], { replaceUrl: true });
    return false;
  }

}
