import * as tslib_1 from "tslib";
import { Router } from '@angular/router';
import { FeatureFlagService } from '../services/feature-flag.service';
import { AuthenticationService } from '../authentication/authentication.service';
var FeatureToggleGuard = (function () {
    function FeatureToggleGuard(_ffService, _route, _authService) {
        this._ffService = _ffService;
        this._route = _route;
        this._authService = _authService;
    }
    FeatureToggleGuard.prototype.canActivate = function (route) {
        var _this = this;
        return new Promise(function (resolve, reject) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var path, allow;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this._authService.credentials) return [3, 2];
                        return [4, this._ffService.getBackendFeatureFlag()];
                    case 1:
                        _a.sent();
                        path = route.routeConfig.path.toUpperCase();
                        path === 'CARDS' && (path = 'CARD');
                        allow = this._ffService.getFeatureFlag(path);
                        if (!allow) {
                            this._route.navigate(['/profile'], { replaceUrl: true });
                            resolve(false);
                        }
                        _a.label = 2;
                    case 2:
                        resolve(true);
                        return [2];
                }
            });
        }); });
    };
    return FeatureToggleGuard;
}());
export { FeatureToggleGuard };
