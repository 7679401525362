import * as i0 from "./bari-breadcrumb.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "./bari-breadcrumb.component";
var styles_BariBreadcrumbComponent = [i0.styles];
var RenderType_BariBreadcrumbComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BariBreadcrumbComponent, data: {} });
export { RenderType_BariBreadcrumbComponent as RenderType_BariBreadcrumbComponent };
function View_BariBreadcrumbComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(2, null, ["", ""]))], function (_ck, _v) { var currVal_2 = _v.parent.context.$implicit.link; _ck(_v, 1, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).target; var currVal_1 = i1.ɵnov(_v, 1).href; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_3 = _v.parent.context.$implicit.title; _ck(_v, 2, 0, currVal_3); }); }
function View_BariBreadcrumbComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.title; _ck(_v, 1, 0, currVal_0); }); }
function View_BariBreadcrumbComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "li", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BariBreadcrumbComponent_2)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BariBreadcrumbComponent_3)), i1.ɵdid(4, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit.link; _ck(_v, 2, 0, currVal_0); var currVal_1 = !_v.context.$implicit.link; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_BariBreadcrumbComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "ul", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BariBreadcrumbComponent_1)), i1.ɵdid(2, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.items; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_BariBreadcrumbComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "bari-breadcrumb", [], null, null, null, View_BariBreadcrumbComponent_0, RenderType_BariBreadcrumbComponent)), i1.ɵdid(1, 114688, null, 0, i4.BariBreadcrumbComponent, [i2.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BariBreadcrumbComponentNgFactory = i1.ɵccf("bari-breadcrumb", i4.BariBreadcrumbComponent, View_BariBreadcrumbComponent_Host_0, {}, {}, []);
export { BariBreadcrumbComponentNgFactory as BariBreadcrumbComponentNgFactory };
