import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { Logger } from '../logger.service';
import { Router } from '@angular/router';
var log = new Logger('Token Interceptor');
var TokenInterceptor = (function () {
    function TokenInterceptor(_router) {
        this._router = _router;
    }
    Object.defineProperty(TokenInterceptor.prototype, "_credentials", {
        get: function () {
            log.debug('get _credentials()');
            return JSON.parse(sessionStorage.getItem('credentials') || localStorage.getItem('credentials'));
        },
        enumerable: true,
        configurable: true
    });
    TokenInterceptor.prototype.intercept = function (request, next) {
        var _this = this;
        log.debug('Credentials', this._credentials);
        if (this._credentials) {
            request = request.clone({
                setHeaders: {
                    'Authorization': "Bearer " + this._credentials.token.access_token
                }
            });
        }
        else if (this._router.url.indexOf('login') === -1 &&
            this._router.url.indexOf('reset-password') === -1 &&
            this._router.url.indexOf('validate-token') === -1 &&
            this._router.url.indexOf('new-password') === -1) {
            window.location.reload();
        }
        return next.handle(request).pipe(tap(function (event) {
            if (event instanceof HttpResponse) {
            }
        }, function (err) {
            if (err instanceof HttpErrorResponse) {
                log.debug('Status Code', err.status);
                if (_this._router.url !== '/login' && err.status === 401) {
                    sessionStorage.removeItem('credentials');
                    _this._router.navigate(['/login/logout']);
                }
            }
        }));
    };
    return TokenInterceptor;
}());
export { TokenInterceptor };
