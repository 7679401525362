import * as tslib_1 from "tslib";
import { ElementRef, OnInit } from '@angular/core';
import { AuthenticationService } from '@bari/core';
import { CustomersService } from '@bari/core/services/customers.service';
import { Router } from '@angular/router';
var BariAvatarComponent = (function () {
    function BariAvatarComponent(_service, _authService, _router) {
        this._service = _service;
        this._authService = _authService;
        this._router = _router;
        this.menuIsOpen = false;
    }
    BariAvatarComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var user;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this._authService.credentials) return [3, 2];
                        return [4, this._service.getAllCustomerInformation(this._authService.credentials.username)];
                    case 1:
                        user = _a.sent();
                        this.name = user.name;
                        _a.label = 2;
                    case 2: return [2];
                }
            });
        });
    };
    BariAvatarComponent.prototype.logout = function () {
        sessionStorage.clear();
        location.reload();
    };
    BariAvatarComponent.prototype.profile = function () {
    };
    BariAvatarComponent.prototype.toggleSubmenu = function () {
        var e = this.submenu.nativeElement;
        if (this.menuIsOpen) {
            e.classList.remove('opened');
            e.classList.add('closed');
            this.menuIsOpen = false;
        }
        else {
            e.classList.add('opened');
            e.classList.remove('closed');
            this.menuIsOpen = true;
        }
    };
    BariAvatarComponent.prototype.getName = function (firstLetter) {
        if (this.name) {
            if (firstLetter) {
                return this.name[0];
            }
            else {
                return this.name.split(' ')[0];
            }
        }
    };
    BariAvatarComponent.prototype.verifyMenu = function () {
        return;
    };
    return BariAvatarComponent;
}());
export { BariAvatarComponent };
