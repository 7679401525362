import { ToastrService } from 'ngx-toastr';
import * as i0 from "@angular/core";
import * as i1 from "ngx-toastr";
export var ToastrType;
(function (ToastrType) {
    ToastrType[ToastrType["NORMAL"] = 0] = "NORMAL";
    ToastrType[ToastrType["SUCCESS"] = 1] = "SUCCESS";
    ToastrType[ToastrType["WARNING"] = 2] = "WARNING";
    ToastrType[ToastrType["ERROR"] = 3] = "ERROR";
})(ToastrType || (ToastrType = {}));
var BariToastrService = (function () {
    function BariToastrService(toastrService) {
        this.toastrService = toastrService;
        this.config = {
            toastClass: 'ngx-toastr bari-toastr'
        };
    }
    BariToastrService.prototype.show = function (type, title, message) {
        switch (type) {
            case ToastrType.NORMAL:
                var config = {
                    toastClass: 'ngx-toastr bari-toastr info'
                };
                this.toastrService.show(message, title, config);
                break;
            case ToastrType.SUCCESS:
                this.toastrService.success(message, title, this.config);
                break;
            case ToastrType.WARNING:
                this.toastrService.warning(message, title, this.config);
                break;
            case ToastrType.ERROR:
                this.toastrService.error(message, title, this.config);
                break;
        }
    };
    BariToastrService.ngInjectableDef = i0.defineInjectable({ factory: function BariToastrService_Factory() { return new BariToastrService(i0.inject(i1.ToastrService)); }, token: BariToastrService, providedIn: "root" });
    return BariToastrService;
}());
export { BariToastrService };
