import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, Renderer2, SimpleChanges } from '@angular/core';
import { IconTypes, ButtonDirection, HoverType } from './bari-button.type';
import { ButtonAnimation } from '@bari/shared/animations/button.animation';

@Component({
  selector: 'bari-button',
  templateUrl: './bari-button.component.html',
  styleUrls: ['./bari-button.component.scss'],
  animations: ButtonAnimation.animations,
  entryComponents: []
})
export class BariButtonComponent implements OnInit, OnChanges {

  @Input() busy: boolean;
  @Input() busyIcon = 'loading_boucing_branco';
  @Input() icon: IconTypes;
  @Input() iconColor = '#fff';
  @Input() iconSize = 22;
  @Input() class: string;
  @Input() color: string;
  @Input() type = 'button';
  @Input() text: string;
  @Input() textColor: string;
  @Input() border: string;
  @Input() size: string;
  @Input() direction: ButtonDirection;
  @Input() decoration: string;
  @Input() padding: string;
  @Input() hover: HoverType;
  @Output() action = new EventEmitter();

  private _disabledButton = false;
  private _nativeElement: ElementRef['nativeElement'];

  public iconFrames: { start?: number; end: number; final?: number; };
  public finalIcon() {
    return (this.icon) ? `assets/images/icons/${this.icon}.svg` : null;
  }

  @Input('disabled')
  set disabled(value) {
    this._disabledButton = value;
  }

  get disabled() {
    return this._disabledButton;
  }

  public validDisabled() {
    return !!this.disabled;
  }

  constructor(private el: ElementRef, private renderer: Renderer2) {
    this._nativeElement = this.el.nativeElement;
  }

  private _addClassToButton(typeOfStyle: string, variantOfStyle: string) {

    const button = this._nativeElement.querySelector('button');
    const classes = button.classList.toString();
    const type = new RegExp(typeOfStyle);

    if (type.test(classes)) {
      const classesMatched = classes.match(type);
      const classToRemove = classesMatched.input.split(' ').filter(item => {
        return item.includes(typeOfStyle);
      })[0];
      this.renderer.removeClass(button, classToRemove);
    }

    if (/ /.test(variantOfStyle)) { // Check if has multiple variants for one style type
      variantOfStyle.split(' ').forEach(variant => {
        this.renderer.addClass(button, `btn-${typeOfStyle}-${variant}`);
      });
    } else {
      this.renderer.addClass(button, `btn-${typeOfStyle}-${variantOfStyle}`);
    }
  }

  /**
   * Remove all class of father element and add only child element <button>
   */
  private _removeClassOfFather() {
    if (this.class) {
      this.class.split(' ').forEach(theCLass => {
        this.renderer.removeClass(this._nativeElement, theCLass);
      });
    }
  }

  private _validateClassToButton() {

    if (this.text) {
      this._addClassToButton('text', this.text);
    }

    if (this.border) {
      this._addClassToButton('border', this.border);
    }

    if (this.color) {
      this._addClassToButton('color', this.color);
    }

    if (this.size) {
      this._addClassToButton('size', this.size);
    }

    if (this.direction) {
      this._addClassToButton('direction', this.direction);
    }

    if (this.decoration) {
      this._addClassToButton('decoration', this.decoration);
    }

    if (this.hover) {
      this._addClassToButton('hover', this.hover);
    }

    if (this.padding) {
      this._addClassToButton('padding', this.padding);
    }

  }

  ngOnInit() {

    if (this.color !== 'dark' && this.textColor !== 'white') {
      this.busyIcon = 'loading_boucing_preto';
    }

    this._removeClassOfFather();
    this._validateClassToButton();
  }

  public ngOnChanges(event: SimpleChanges) {
    // console.warn(event);
    this._validateClassToButton();
    if (event.busy && !event.busy.firstChange) {
      if (event.busy.currentValue && !event.busy.firstChange) {
        this.iconFrames = {
          start: 13,
          end: 40
        };
      } else if (event.busy.previousValue && !event.busy.currentValue) {
        this.iconFrames = {
          end: 40,
          final: 75
        };
      }
    }
  }

  public click() {
    if (!this._disabledButton && !this.busy) {
      this.action.emit();
    }
  }

}
