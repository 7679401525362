import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthenticationGuard } from './core';
import { ShellComponent } from './shell/shell.component';
import { FeatureToggleGuard } from './core/guard/feature-flag.guard';
// import { AlertComponent } from './modules/alert-module/alert.component';
// import { NotFoundComponent } from './modules/not-found-module/not-found-module.component';

const routes: Routes = [
  // Fallback when no prior route is matched
  { path: '', redirectTo: '/account', pathMatch: 'full' },

  {
    path: 'cards',
    component: ShellComponent,
    canActivate: [FeatureToggleGuard, AuthenticationGuard],
    loadChildren: './modules/cards-module/cards.module#CardsModule'
  },

  {
    path: 'account',
    component: ShellComponent,
    canActivate: [FeatureToggleGuard, AuthenticationGuard],
    loadChildren: './modules/account-module/account.module#AccountModule'
  },
  {
    path: 'login',
    component: ShellComponent,
    // loadChildren: './modules/login-module/login.module#LoginModule',
    loadChildren: './modules/login-module/login.module#LoginModule'
  },
  {
    path: 'error',
    component: ShellComponent,
    canActivate: [AuthenticationGuard],
    loadChildren: './modules/error-module/error.module#ErrorModule'
  },

  {
    path: 'profile',
    component: ShellComponent,
    canActivate: [AuthenticationGuard],
    loadChildren: './modules/profile-module/profile.module#ProfileModule'
  },
  // Shell.childRoutes([
  // ]),

  // Modal's Router
  {
    path: 'change-due-date',
    outlet: 'modal',
    loadChildren:
      './shared/modal/change-due-date-modal-module/change-due-date-modal.module#ChangeDueDateModalModule'
  },
  {
    path: 'plastic-card-block-modal',
    outlet: 'modal',
    loadChildren:
      './shared/modal/plastic-card-block-modal-module/plastic-card-block-modal.module#PlasticCardBlockModalModule'
  },
  {
    path: 'transaction-details-modal-module',
    outlet: 'modal',
    loadChildren:
      './shared/modal/transaction-details-modal-module/transaction-details-modal.module#TransactionDetailsModalModule'
  },
  {
    path: 'transaction-problems-modal',
    outlet: 'modal',
    loadChildren:
      './shared/modal/transaction-problems-modal/transaction-problems-modal.module#TransactionProblemsModalModule'
  },
  {
    path: 'plastic-card-first-time-modal',
    outlet: 'modal',
    loadChildren:
      './shared/modal/plastic-card-first-time-modal-module/plastic-card-first-time-modal.module#PlasticCardFirstTimeModalModule'
  },
  {
    path: 'alert',
    outlet: 'modal',
    loadChildren: './shared/modal/alert-module/alert.module#AlertModule'
  },
  {
    path: 'billet-modal',
    outlet: 'modal',
    loadChildren: './shared/modal/billet-modal-module/billet-modal.module#BilletModalModule'
  },
  {
    path: 'fees-modal',
    outlet: 'modal',
    loadChildren: './shared/modal/fees-modal-module/fees-modal.module#FeesModalModule'
  },
  {
    path: 'confirm-change-due-date',
    outlet: 'modal',
    loadChildren:
      './shared/modal/confirm-change-due-date-modal-module/confirm-change-due-date-modal.module#ConfirmChangeDueDateModalModule'
  },
  {
    path: 'confirm-contest-modal',
    outlet: 'modal',
    loadChildren:
      './shared/modal/confirm-contest-modal-module/confirm-contest-modal.module#ConfirmContestModalModule'
  },
  {
    path: 'limit-modal',
    outlet: 'modal',
    loadChildren: './shared/modal/limit-modal-module/limit-modal.module#LimitModalModule'
  },
  {
    path: 'travel-warning-modal',
    outlet: 'modal',
    loadChildren:
      // tslint:disable-next-line:max-line-length
      './modules/cards-module/pages/statement-module/module/travel-warning-modal-module/travel-warning-modal.module#TravelWarningModalModule'
  },
  {
    path: 'travel-success-modal',
    outlet: 'modal',
    loadChildren:
      // tslint:disable-next-line:max-line-length
      './modules/cards-module/pages/statement-module/module/travel-success-modal-module/travel-success-modal.module#TravelSuccessModalModule'
  },
  {
    path: 'delay-alert-modal',
    outlet: 'modal',
    loadChildren:
      './shared/modal/delay-alert-modal-module/delay-alert-modal.module#DelayAlertModalModule'
  },
  {
    path: 'invite-resend-modal',
    outlet: 'modal',
    loadChildren:
      './shared/modal/invite-resend-modal/invite-resend-modal.module#InviteResendModalModule'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: []
})
export class AppRoutingModule {}
