import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService, Credentials } from '../authentication/authentication.service';





export interface WalletContext {

  customerId: number;
  accountId: number;
  agency: string;
  balance: number;

}


@Injectable()
export class WalletService {

  private _user: Credentials;

  constructor(private http: HttpClient, private userService: AuthenticationService) {
    this._user = userService.credentials;
  }

  public getWallet(accountId: number): Promise<WalletContext[]> {
    return new Promise<WalletContext[]>((resolve, reject) => {
      // this.http.get(`{api}/account/v1/accounts/${accountId}/accounts?customerId=${this._user.username}`)
      this.http.get(`{api}/account/v1/accounts?customerId=${this._user.username}`)
      .toPromise()
      .then(
        (data: WalletContext[]) => {
          resolve(data);

        },
        error => {
          reject(error.error);
        }
        );
      });

    }
  }
