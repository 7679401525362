import { Deserializable } from './deserializable.model';
import {formatCurrency} from '@angular/common';
import { ɵConsole } from '@angular/core';
import * as moment from 'moment';


export class Transaction implements Deserializable {
  transactionId: string;
  cardId: number;
  lastCardDigits: string;
  value: number;
  dateTime: string;
  category: string;
  type: string; // purchase, adjustment, previousInvoice
  installmentNumber: number;
  totalInstallments: number;
  establishmentName: string;
  billingDate?: string;
  hasDispute: boolean;
  contested?: boolean; // TODO: remover do mock
  isDisputable?: boolean;

  totalPages: number;
  totalItems: number;
  itemsPerPage: number;
  id: number;
  accountId: number;
  description: string;
  operationType: string;

  public dateFormatation() {

    const FormatCalendar = moment(this.dateTime).calendar( null, {
      lastDay : '[Ontem às] LT',
      sameDay : '[Hoje às] LT',
      nextDay : '[Amanhã às] LT',
      lastWeek : 'dddd [às] LT',
      nextWeek : 'dddd [às] LT',
      sameElse : 'DD [de] MMMM'
    });

    return FormatCalendar;
  }


  public getOperationTypeDetails() {
    if (this.operationType) {
      return this.operationType;
    }

    if (this.totalInstallments && this.totalInstallments > 1) {
      return `${this.installmentNumber} de ${this.totalInstallments}x`;
    }

    if (this.value < 0 && this.type === 'adjustment' ) {
      return 'Estorno';
    }

    // Transaction Wallet
    if (this.category === 'INVEST_WITHDRAW') {
      return 'Resgate';
    }
    if (this.category === 'INVEST_APPLY') {
      return 'Estorno';
    }

  }

  public labelColorByTransactionType(description?: string) {
    const colorsClass = [];

    if (this.category) {
      switch (this.category) {
        case 'INVEST_WITHDRAW':
          if (!description) {
            colorsClass.push('value-green');
          }
        break;
        case 'INFO':
          colorsClass.push('value-green');
        break;

      }

    }

    switch (this.type) {
      case 'adjustment':
      if (this.value < 0) {
        colorsClass.push('value-green');
      } else {
        colorsClass.push('value-blue');
      }
      break;

      case 'previousInvoice':
      colorsClass.push('value-green');
      break;

    }

    return colorsClass;

  }

  public labelBorderByTransactionType() {
    switch (this.category) {
      case 'INFO':
      return 'category-info';
    }
  }

  public getValue(mode: string) {

    switch (mode) {

      case 'show-plus':
      const valueFormated = this.value / 100;

      return formatCurrency(valueFormated, 'pt-BR', valueFormated >= 0 ? '+ R$' : ' R$');

      break;

      default:

      return (this.value < 0) ? this.value * -1 : this.value;

      break;

    }

  }

  public checkHasDispute() {
    return this.hasDispute;
  }

  deserialize(input: any): this {
    return Object.assign(this, input);
  }
}

export class PaginatedTransaction  implements Deserializable {
  page: number;
  items: Transaction[];

  deserialize(input: any): this {
    return Object.assign(this, input);
  }
}
