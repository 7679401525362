import * as i0 from "./bari-menu.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "../../../shared/components/bari-button/bari-button.component.ngfactory";
import * as i4 from "../../../shared/components/bari-button/bari-button.component";
import * as i5 from "@angular/common";
import * as i6 from "./bari-menu.component";
import * as i7 from "../../../core/authentication/authentication.service";
var styles_BariMenuComponent = [i0.styles];
var RenderType_BariMenuComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BariMenuComponent, data: {} });
export { RenderType_BariMenuComponent as RenderType_BariMenuComponent };
function View_BariMenuComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "li", [], null, null, null, null, null)), i1.ɵdid(1, 1720320, null, 2, i2.RouterLinkActive, [i2.Router, i1.ElementRef, i1.Renderer2, i1.ChangeDetectorRef], { routerLinkActive: [0, "routerLinkActive"] }, null), i1.ɵqud(603979776, 1, { links: 1 }), i1.ɵqud(603979776, 2, { linksWithHrefs: 1 }), i1.ɵpad(4, 1), (_l()(), i1.ɵeld(5, 0, null, null, 4, "bari-button", [["border", "no"], ["class", "btn btn-font-gray"], ["size", "medium"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 6).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_BariButtonComponent_0, i3.RenderType_BariButtonComponent)), i1.ɵdid(6, 16384, [[1, 4]], 0, i2.RouterLink, [i2.Router, i2.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(7, 1), i1.ɵdid(8, 638976, null, 0, i4.BariButtonComponent, [i1.ElementRef, i1.Renderer2], { class: [0, "class"], border: [1, "border"], size: [2, "size"] }, null), (_l()(), i1.ɵted(9, 0, [" ", " "]))], function (_ck, _v) { var currVal_0 = _ck(_v, 4, 0, "active"); _ck(_v, 1, 0, currVal_0); var currVal_1 = _ck(_v, 7, 0, _v.context.$implicit.href); _ck(_v, 6, 0, currVal_1); var currVal_2 = "btn btn-font-gray"; var currVal_3 = "no"; var currVal_4 = "medium"; _ck(_v, 8, 0, currVal_2, currVal_3, currVal_4); }, function (_ck, _v) { var currVal_5 = _v.context.$implicit.name; _ck(_v, 9, 0, currVal_5); }); }
export function View_BariMenuComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "nav", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "ul", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BariMenuComponent_1)), i1.ɵdid(3, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.menuItens; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_BariMenuComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "bari-menu", [], null, null, null, View_BariMenuComponent_0, RenderType_BariMenuComponent)), i1.ɵdid(1, 114688, null, 0, i6.BariMenuComponent, [i7.AuthenticationService, i2.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BariMenuComponentNgFactory = i1.ɵccf("bari-menu", i6.BariMenuComponent, View_BariMenuComponent_Host_0, {}, {}, []);
export { BariMenuComponentNgFactory as BariMenuComponentNgFactory };
