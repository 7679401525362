import * as i0 from "./bari-animation.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/ng-lottie/dist/esm/src/lottieAnimationView.component.ngfactory";
import * as i3 from "ng-lottie/dist/esm/src/lottieAnimationView.component";
import * as i4 from "./bari-animation.component";
var styles_BariAnimationComponent = [i0.styles];
var RenderType_BariAnimationComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BariAnimationComponent, data: {} });
export { RenderType_BariAnimationComponent as RenderType_BariAnimationComponent };
export function View_BariAnimationComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "lottie-animation-view", [], null, [[null, "animCreated"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("animCreated" === en)) {
        var pd_0 = (_co.animCreated($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_LottieAnimationViewComponent_0, i2.RenderType_LottieAnimationViewComponent)), i1.ɵdid(1, 114688, null, 0, i3.LottieAnimationViewComponent, [i1.PLATFORM_ID], { options: [0, "options"], width: [1, "width"] }, { animCreated: "animCreated" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.configuration; var currVal_1 = _co.width; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_BariAnimationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "bari-animation", [], null, null, null, View_BariAnimationComponent_0, RenderType_BariAnimationComponent)), i1.ɵdid(1, 114688, null, 0, i4.BariAnimationComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BariAnimationComponentNgFactory = i1.ɵccf("bari-animation", i4.BariAnimationComponent, View_BariAnimationComponent_Host_0, { animation: "animation", width: "width", loop: "loop", autoplay: "autoplay", setFrame: "setFrame" }, {}, []);
export { BariAnimationComponentNgFactory as BariAnimationComponentNgFactory };
