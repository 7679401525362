<div class="row" [style.display]="state.value === 'inactive' ? 'none' : ''">
  <div class="col-xs-10 alert-content">
    <div *ngIf="title" [class]="options.titleClass" [attr.aria-label]="title">
      {{ title }}
    </div>
    <div *ngIf="message && options.enableHtml" role="alert" aria-live="polite"
         [class]="options.messageClass" [innerHTML]="message">
    </div>
    <div *ngIf="message && !options.enableHtml" role="alert" aria-live="polite"
         [class]="options.messageClass" [attr.aria-label]="message">
      {{ message }}
    </div>
  </div>
  <div class="col-xs-2 text-right alert-content">
    <button aria-label="Close"
            (click)="remove()"
            class="toast-close-button close-button ng-star-inserted">
    </button>
  </div>
</div>
