import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Logger } from '../logger.service';
import { AuthenticationService, Credentials } from '../authentication/authentication.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { CycleContext } from '@bari/core/services/cycles.service';

const log = new Logger('CreditCardService');

export interface CreditCardContext {
  dependents: any[];

  id: number;
  accountId: number;
  printedName: string;
  lastDigits: string;
  currentDebt: number;
  expiryDate: string;
  state: string;
  status: string;
  product: string;
  type: string;
  cycleId: number;
}

export interface CreditCardLimitsContext {
  purchaseLimitAvailable: number;
  purchaseLimitTotal: number;
  purchaseLimitMinimal: number;
  purchaseLimitPreApproved: number;
  withdrawLimitAvailable: number;
  withdrawLimitTotal: number;
}

export interface CardCache {
  [key: number]: CreditCardContext;
}

export interface CreditCardCycleContext extends CycleContext {
  requestedCycleId: number;
  cutoffDate: string;
  dueDate: string;
  bestPurchaseDate: string;
  lastCycleUpdateDate: string;
  daysSinceLastUpdate: number;
  remainingDaysForUpdate: number;
  canBeUpdated: boolean;
  hasPendingUpdateRequest: boolean;
}

export interface Account {
  parentAccountId: number;
  parentCardId: number;
  accountId: number;
  cards: Card[];
  cycleId: number;
  productId: number;
  hasOverdueInvoice: boolean;
  dependents: [];
}

export interface Card {
  cardId: number;
  lastDigits: string;
}

@Injectable()
export class CreditCardService {

  private _user: Credentials;

  private _cardCache: CardCache = {};
  public cacheChange: Subject<CardCache> = new Subject<CardCache>();

  private _dependentsCardCache: CardCache = {};
  public dependentsCacheChange: Subject<CreditCardContext[]> = new Subject<CreditCardContext[]>();

  private _creditCards: CreditCardContext[];
  public creditCardsSubject: Subject<CreditCardContext> = new Subject<CreditCardContext>();

  private readonly _cardLimitInitialValue: CreditCardLimitsContext = {
    purchaseLimitAvailable: 0, purchaseLimitTotal: 0, purchaseLimitPreApproved: 0, purchaseLimitMinimal: 0,
    withdrawLimitAvailable: 0, withdrawLimitTotal: 0
  };
  private _cardLimit = new BehaviorSubject<CreditCardLimitsContext>(this._cardLimitInitialValue);
  public cardLimit = this._cardLimit.asObservable();

  public creditCardsFilter(card: CreditCardContext) {
    this.creditCardsSubject.next(card);
  }

  // TODO: Implementar Subject nos cartões, e não apenas no cache.

  constructor(private http: HttpClient, private userService: AuthenticationService) {
    this._user = userService.credentials;
  }

  private _mapCachedCard(body: any) {
    this._cardCache[`${body.id}`] = new Object(body);
    return this._cardCache[body.id];
  }

  private _mapCachedDependentsCard(body: any) {
    this._dependentsCardCache[`${body.id}`] = new Object(body);
    return this._dependentsCardCache[body.id];
  }

  public getCreditCards(): Promise<[CreditCardContext]> {
    log.debug('Call getCreditCards');

    return new Promise<[CreditCardContext]>((resolve, reject) => {
      this.http.cache().get(`v1/creditcards?uuid=${this._user.username}`).subscribe(
        (data: [CreditCardContext]) => {

          data.map(card => this._mapCachedCard(card));

          resolve(data);
        },
        error => {
          reject(error.message);
        });
    });

  }

  public getCreditCardsOfAllDependents(): Promise<CreditCardContext[]> {
    log.debug('Call getCreditCardsDependents');
    return new Promise<CreditCardContext[]>((resolve, reject) => {
      this.getCreditCards().then((cards) => {
        cards.map((card) => {
          if (card.type === 'holder') {
            this.http.cache().get(`v1/creditcards/${card.id}/dependents?uuid=${this._user.username}`).subscribe(
              (data: CreditCardContext) => {
                data.dependents.map(dependentCard => this._mapCachedDependentsCard(dependentCard));
                this.dependentsCacheChange.next(data.dependents);
                resolve(data.dependents);
              },
              error => {
                reject(error.message);
              });
          }
        });
      });
    });
  }

  public getCreditCard(cardId: number): Promise<CreditCardContext> {

    log.debug('Call getCreditCard', this._cardCache[cardId]);

    return new Promise<CreditCardContext>((resolve, reject) => {

      if (this._cardCache[cardId]) {
        resolve(this._cardCache[cardId]);
      } else {
        this.http.cache().get(`v1/creditcards/${cardId}`).subscribe(
          (data: CreditCardContext) => {
            if (data['error']) {
              reject(data['error']);
            }
            resolve(data);
          },
          error => {
            reject(error.message);
          }
        );
      }


    });

  }

  // TODO: pedir para o back-end retornar os limites atualizados, limite disponível continua vindo com o valor antigo
  public getCardLimits(cardId: number): Promise<CreditCardLimitsContext> {
    return new Promise<CreditCardLimitsContext>((resolve, reject) => {
      this.http.get(`v1/creditcards/${cardId}/limits?uuid=${this._user.username}`).toPromise()
        .then(
          (data: CreditCardLimitsContext) => {
            this._cardLimit.next(data);
            resolve(data);
          },
          error => {
            reject(error.message);
          }
        );
    });
  }

  public getAccount(): Promise<{ uuid: string; accounts: Account[]; }> {
    return new Promise((resolve, reject) => {
      if (this._user) {
        this.http.get(`v1/accounts?uuid=${this._user.username}`).toPromise()
          .then(
            (data: { uuid: string; accounts: Account[]; }) => {
              resolve(data);
            },
            error => {
              reject(error.message);
            }
          );
      }
    });
  }

  /* public getCheckOverdueInvoice(): Promise<Account> {
    return new Promise(async (resolve, reject) => {
      const accResponse = await this.getAccount();
      console.warn('getCheckOverdueInvoice', accResponse);
      this.http.get(`v1/accounts/${accResponse.accounts[0].accountId}?uuid=${this._user.username}`).toPromise()
        .then(
          (data: Account) => {
            resolve(data);
          },
          error => {
            reject(error.message);
          }
        );
    });
  } */

  public putCardLimits(cardId: number, purchaseLimit: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.put(`v1/creditcards/${cardId}/limits`, {
        purchaseLimit: purchaseLimit,
        uuid: this._user.username
      }).toPromise()
        .then(
          (limit: CreditCardLimitsContext) => {
            this._cardLimit.next(limit);
            resolve(limit);
          },
          error => {
            reject(error.error);
          }
        );
    });
  }

  public putCardStatus(status: 'active' | 'blocked', cardId: number, password?: string) {

    const putParams: object = {
      uuid: this._user.username,
      status: status,
    };


    if (password) {
      putParams['password'] = password;
    }

    return new Promise((resolve, reject) => {

      this.http.put(`v1/creditcards/${cardId}/status`, putParams).toPromise()
        .then(
          (data) => {
            this._cardCache[cardId].status = status;
            this.cacheChange.next(this._cardCache);
            resolve(data);
          },
          error => { reject(error); }
        );
    });
  }

  public postCheckCardLastNumbers(cardId: number, lastDigits: string) {
    return new Promise((resolve, reject) => {
      this.http.post(`v1/creditcards/${cardId}/validate`, {
        validationType: 'lastDigits',
        digits: lastDigits,
        uuid: this._user.username
      }).toPromise()
        .then(
          (data) => {
            resolve(data);
          },
          error => { reject(error); }
        );
    });
  }

  public putCardCycle(cardId: number, cycleId: number) {
    return new Promise((resolve, reject) => {
      this.http.put(`v1/creditcards/${cardId}/cycle`, {
        uuid: this._user.username, cycleId: cycleId
      }).toPromise()
        .then(
          (data) => {
            resolve(data);
          },
          error => {
            reject(error.error);
          }
        );
    });
  }

  public getCardCycle(cardId: number): Promise<CreditCardCycleContext> {
    return new Promise((resolve, reject) => {
      this.http.get(`v1/creditcards/${cardId}/cycle?uuid=${this._user.username}`).toPromise().then(
        (data: CreditCardCycleContext) => {
          resolve(data);
        },
        error => {
          reject(error.error);
        }
      );
    });
  }

  public getCountry(): Promise<any> {
    return new Promise((resolve, reject) => {
      resolve([
        { name: 'África' },
        { name: 'América' },
        { name: 'Ásia' },
        { name: 'Europa' },
        { name: 'Oceania' },
        { name: 'Antártida' },
      ]);
    });
  }

  public putTravelMode(country?: any): Promise<any> {
    return new Promise((resolve, reject) => {
      resolve();
    });
  }

}
