import { ElementRef, EventEmitter, OnChanges, OnInit, Renderer2, SimpleChanges } from '@angular/core';
var BariButtonComponent = (function () {
    function BariButtonComponent(el, renderer) {
        this.el = el;
        this.renderer = renderer;
        this.busyIcon = 'loading_boucing_branco';
        this.iconColor = '#fff';
        this.iconSize = 22;
        this.type = 'button';
        this.action = new EventEmitter();
        this._disabledButton = false;
        this._nativeElement = this.el.nativeElement;
    }
    BariButtonComponent.prototype.finalIcon = function () {
        return (this.icon) ? "assets/images/icons/" + this.icon + ".svg" : null;
    };
    Object.defineProperty(BariButtonComponent.prototype, "disabled", {
        get: function () {
            return this._disabledButton;
        },
        set: function (value) {
            this._disabledButton = value;
        },
        enumerable: true,
        configurable: true
    });
    BariButtonComponent.prototype.validDisabled = function () {
        return !!this.disabled;
    };
    BariButtonComponent.prototype._addClassToButton = function (typeOfStyle, variantOfStyle) {
        var _this = this;
        var button = this._nativeElement.querySelector('button');
        var classes = button.classList.toString();
        var type = new RegExp(typeOfStyle);
        if (type.test(classes)) {
            var classesMatched = classes.match(type);
            var classToRemove = classesMatched.input.split(' ').filter(function (item) {
                return item.includes(typeOfStyle);
            })[0];
            this.renderer.removeClass(button, classToRemove);
        }
        if (/ /.test(variantOfStyle)) {
            variantOfStyle.split(' ').forEach(function (variant) {
                _this.renderer.addClass(button, "btn-" + typeOfStyle + "-" + variant);
            });
        }
        else {
            this.renderer.addClass(button, "btn-" + typeOfStyle + "-" + variantOfStyle);
        }
    };
    BariButtonComponent.prototype._removeClassOfFather = function () {
        var _this = this;
        if (this.class) {
            this.class.split(' ').forEach(function (theCLass) {
                _this.renderer.removeClass(_this._nativeElement, theCLass);
            });
        }
    };
    BariButtonComponent.prototype._validateClassToButton = function () {
        if (this.text) {
            this._addClassToButton('text', this.text);
        }
        if (this.border) {
            this._addClassToButton('border', this.border);
        }
        if (this.color) {
            this._addClassToButton('color', this.color);
        }
        if (this.size) {
            this._addClassToButton('size', this.size);
        }
        if (this.direction) {
            this._addClassToButton('direction', this.direction);
        }
        if (this.decoration) {
            this._addClassToButton('decoration', this.decoration);
        }
        if (this.hover) {
            this._addClassToButton('hover', this.hover);
        }
        if (this.padding) {
            this._addClassToButton('padding', this.padding);
        }
    };
    BariButtonComponent.prototype.ngOnInit = function () {
        if (this.color !== 'dark' && this.textColor !== 'white') {
            this.busyIcon = 'loading_boucing_preto';
        }
        this._removeClassOfFather();
        this._validateClassToButton();
    };
    BariButtonComponent.prototype.ngOnChanges = function (event) {
        this._validateClassToButton();
        if (event.busy && !event.busy.firstChange) {
            if (event.busy.currentValue && !event.busy.firstChange) {
                this.iconFrames = {
                    start: 13,
                    end: 40
                };
            }
            else if (event.busy.previousValue && !event.busy.currentValue) {
                this.iconFrames = {
                    end: 40,
                    final: 75
                };
            }
        }
    };
    BariButtonComponent.prototype.click = function () {
        if (!this._disabledButton && !this.busy) {
            this.action.emit();
        }
    };
    return BariButtonComponent;
}());
export { BariButtonComponent };
