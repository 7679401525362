import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AuthenticationService, Credentials } from '../authentication/authentication.service';
import { Subject, Observable } from 'rxjs';
import { TransactionContext } from '@bari/core/services/transactions.service';
import { Transaction } from '@bari/shared/model/transaction.model';
import { map } from 'rxjs/operators';


export interface InvoiceContext {
  invoiceId: string;
  totalValue: number;
  minimumPaymentValue: number;
  paidValue: number;
  debt: number;
  hasBeenPaid: boolean;
  isAvailable: boolean;
  type: 'closed' | 'current' | 'future';
  closingDate: string;
  invoiceDueDate: string;
  realDueDate: string;
  payCode: string;
  payNumber: string;
  maskedPayNumber: string;
}

@Injectable()
export class AccountsService {

  private _user: Credentials;

  private _currentInvoice = new Subject<InvoiceContext>();
  public currentInvoice = this._currentInvoice.asObservable();

  constructor(private http: HttpClient, private userService: AuthenticationService) {
    this._user = userService.credentials;
  }

  public setCurrentInvoice(invoice: InvoiceContext) {
    this._currentInvoice.next(invoice);
  }

  public getInvoices(accountId: number): Promise<InvoiceContext[]> {
    return new Promise<InvoiceContext[]>((resolve, reject) => {
      this.http.cache().get(`v1/accounts/${accountId}/invoices?uuid=${this._user.username}`)
        .toPromise()
        .then(
          (data: InvoiceContext[]) => {
            resolve(data);
          },
          error => {
            reject(error.error);
          }
        );
    });
  }

  public getTransactionsByInvoice(accountId: number, invoiceId: string, page?: string | number):
  Observable<{ next: boolean; items: Transaction[] }> {

    let url = `v1/accounts/${accountId}/invoices/${invoiceId}/transactions`;
    url += `?uuid=${this._user.username}&itemsPerPage=6`;

    if (page) {
      url += `&page=${page}`;
    }

    return this.http.cache().get(url).pipe(
      map(
        (data: any) => {
          return {
            next: data.hasNextPage,
            items: data.items.map((transaction: Transaction) => new Transaction().deserialize(transaction))
          };
        }
      )
    );

    /* return new Promise<TransactionContext>((resolve, reject) => {

      this.http.cache().get(url)
        .toPromise()
        .then(
          (data: TransactionContext) => {
            const newTransactions = data.items.map(transaction => );
            data.items = newTransactions;
            resolve(data);
          },
          error => {
            reject(error.error);
          }
        );
    }); */
  }

  public patchTransactionsDispute(transactionId: string, disputeData: any): Promise<TransactionContext> {
    return new Promise<TransactionContext>((resolve, reject) => {
      this.http.patch(`v1/accounts/${this._user.username}/transactions/${transactionId}/dispute`, disputeData)
        .toPromise()
        .then(
          (data: TransactionContext) => {
            resolve(data);
          },
          error => {
            reject(error.error);
          }
        );
    });
  }
}
