import * as i0 from "./shell.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./components/bari-header/bari-header.component.ngfactory";
import * as i3 from "./components/bari-header/bari-header.component";
import * as i4 from "../core/services/feature-flag.service";
import * as i5 from "@angular/router";
import * as i6 from "@angular/common";
import * as i7 from "./components/bari-footer/bari-footer.component.ngfactory";
import * as i8 from "./components/bari-footer/bari-footer.component";
import * as i9 from "../core/authentication/authentication.service";
import * as i10 from "./shell.component";
import * as i11 from "@angular/service-worker";
var styles_ShellComponent = [i0.styles];
var RenderType_ShellComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ShellComponent, data: {} });
export { RenderType_ShellComponent as RenderType_ShellComponent };
function View_ShellComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "bari-header", [], null, null, null, i2.View_BariHeaderComponent_0, i2.RenderType_BariHeaderComponent)), i1.ɵdid(1, 49152, null, 0, i3.BariHeaderComponent, [i4.FeatureFlagService, i5.Router], null, null)], null, null); }
export function View_ShellComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ShellComponent_1)), i1.ɵdid(1, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["id", "inner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(4, 212992, null, 0, i5.RouterOutlet, [i5.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵeld(5, 0, null, null, 1, "bari-footer", [], null, null, null, i7.View_BariFooterComponent_0, i7.RenderType_BariFooterComponent)), i1.ɵdid(6, 114688, null, 0, i8.BariFooterComponent, [i9.AuthenticationService], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showHeader; _ck(_v, 1, 0, currVal_0); _ck(_v, 4, 0); _ck(_v, 6, 0); }, null); }
export function View_ShellComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "bari-shell", [], null, null, null, View_ShellComponent_0, RenderType_ShellComponent)), i1.ɵdid(1, 638976, null, 0, i10.ShellComponent, [i5.Router, i11.SwUpdate], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ShellComponentNgFactory = i1.ɵccf("bari-shell", i10.ShellComponent, View_ShellComponent_Host_0, {}, {}, []);
export { ShellComponentNgFactory as ShellComponentNgFactory };
