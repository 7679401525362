import * as i0 from "./bari-button.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/angular-svg-icon/angular-svg-icon.ngfactory";
import * as i3 from "angular-svg-icon";
import * as i4 from "@angular/common";
import * as i5 from "../bari-animation/bari-animation.component.ngfactory";
import * as i6 from "../bari-animation/bari-animation.component";
import * as i7 from "./bari-button.component";
var styles_BariButtonComponent = [i0.styles];
var RenderType_BariButtonComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BariButtonComponent, data: { "animation": [{ type: 7, name: "enterAnimation", definitions: [{ type: 1, expr: ":enter", animation: [{ type: 6, styles: { transform: "translateY(-50%)", opacity: 0 }, offset: null }, { type: 4, styles: { type: 6, styles: { transform: "translateY(0)", opacity: 1 }, offset: null }, timings: "200ms" }], options: null }, { type: 1, expr: ":leave", animation: [{ type: 6, styles: { transform: "translateY(0)", opacity: 1 }, offset: null }, { type: 4, styles: { type: 6, styles: { transform: "translateY(50%)", opacity: 0 }, offset: null }, timings: "150ms" }], options: null }], options: {} }] } });
export { RenderType_BariButtonComponent as RenderType_BariButtonComponent };
function View_BariButtonComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "svg-icon", [["class", "icon"]], null, null, null, i2.View_SvgIconComponent_0, i2.RenderType_SvgIconComponent)), i1.ɵdid(1, 1032192, null, 0, i3.SvgIconComponent, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2, i3.SvgIconRegistryService, i1.ChangeDetectorRef], { src: [0, "src"], applyCss: [1, "applyCss"], svgStyle: [2, "svgStyle"] }, null), i1.ɵpod(2, { "width.px": 0, "fill": 1 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.finalIcon(); var currVal_1 = "busy"; var currVal_2 = _ck(_v, 2, 0, _co.iconSize, _co.iconColor); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_BariButtonComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "btn-content"]], [[24, "@enterAnimation", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [], null, null, null, null, null)), i1.ɵncd(null, 0), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BariButtonComponent_2)), i1.ɵdid(4, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = ((_co.icon && !_co.iconsIsAnimation) && !_co.busy); _ck(_v, 4, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = undefined; _ck(_v, 0, 0, currVal_0); }); }
function View_BariButtonComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "bari-animation", [["class", "busy"]], [[24, "@enterAnimation", 0]], null, null, i5.View_BariAnimationComponent_0, i5.RenderType_BariAnimationComponent)), i1.ɵdid(1, 114688, null, 0, i6.BariAnimationComponent, [], { animation: [0, "animation"], width: [1, "width"], loop: [2, "loop"], autoplay: [3, "autoplay"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.busyIcon; var currVal_2 = _co.iconSize; var currVal_3 = true; var currVal_4 = true; _ck(_v, 1, 0, currVal_1, currVal_2, currVal_3, currVal_4); }, function (_ck, _v) { var currVal_0 = undefined; _ck(_v, 0, 0, currVal_0); }); }
export function View_BariButtonComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "button", [], [[8, "type", 0], [8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.click() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 278528, null, 0, i4.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(2, { "btn-disabled": 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BariButtonComponent_1)), i1.ɵdid(4, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BariButtonComponent_3)), i1.ɵdid(6, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = i1.ɵinlineInterpolate(1, "btn ", _co.class, ""); var currVal_3 = _ck(_v, 2, 0, _co.disabled); _ck(_v, 1, 0, currVal_2, currVal_3); var currVal_4 = !_co.busy; _ck(_v, 4, 0, currVal_4); var currVal_5 = _co.busy; _ck(_v, 6, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.type; var currVal_1 = _co.validDisabled(); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
export function View_BariButtonComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "bari-button", [], null, null, null, View_BariButtonComponent_0, RenderType_BariButtonComponent)), i1.ɵdid(1, 638976, null, 0, i7.BariButtonComponent, [i1.ElementRef, i1.Renderer2], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BariButtonComponentNgFactory = i1.ɵccf("bari-button", i7.BariButtonComponent, View_BariButtonComponent_Host_0, { busy: "busy", busyIcon: "busyIcon", icon: "icon", iconColor: "iconColor", iconSize: "iconSize", class: "class", color: "color", type: "type", text: "text", textColor: "textColor", border: "border", size: "size", direction: "direction", decoration: "decoration", padding: "padding", hover: "hover", disabled: "disabled" }, { action: "action" }, ["*"]);
export { BariButtonComponentNgFactory as BariButtonComponentNgFactory };
