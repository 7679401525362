import * as i0 from "./bari-footer.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./bari-footer.component";
import * as i3 from "../../../core/authentication/authentication.service";
var styles_BariFooterComponent = [i0.styles];
var RenderType_BariFooterComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BariFooterComponent, data: {} });
export { RenderType_BariFooterComponent as RenderType_BariFooterComponent };
export function View_BariFooterComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "div", [["class", "footer-inner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "logo"], ["style", "text-align:center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [["alt", "Bari Logo"], ["src", "assets/images/logo-branco.svg"], ["width", "60"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 3, "div", [["class", "copyright"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, [" ", " \u00A9 "])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "a", [["href", "#"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Banco Bari S.A"])), (_l()(), i1.ɵeld(7, 0, null, null, 7, "div", [["class", "footer-actions"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 6, "ul", [], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 2, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "a", [["href", "https://files.bancobari.com.br/Contratos/privacidade.pdf"], ["target", "_blank"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Termos de privacidade"])), (_l()(), i1.ɵeld(12, 0, null, null, 2, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 1, "a", [["target", "_blank"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Contato"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.date; _ck(_v, 4, 0, currVal_0); var currVal_1 = _co.getEmail(); _ck(_v, 13, 0, currVal_1); }); }
export function View_BariFooterComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "bari-footer", [], null, null, null, View_BariFooterComponent_0, RenderType_BariFooterComponent)), i1.ɵdid(1, 114688, null, 0, i2.BariFooterComponent, [i3.AuthenticationService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BariFooterComponentNgFactory = i1.ɵccf("bari-footer", i2.BariFooterComponent, View_BariFooterComponent_Host_0, {}, {}, []);
export { BariFooterComponentNgFactory as BariFooterComponentNgFactory };
