import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BariButtonModule } from '@bari/shared/components/bari-button/bari-button.module';

// components
import { BariLoadingFailedComponent } from '@bari/shared/components/bari-loading-failed/bari-loading-failed.component';


@NgModule({
  imports: [
    CommonModule,
    BariButtonModule

  ],
  exports: [
    BariLoadingFailedComponent
  ],
  declarations: [
    BariLoadingFailedComponent
  ]
})
export class BariLoadingFailedModule {
}
